import { createSlice, createAsyncThunk, isAnyOf } from '@reduxjs/toolkit';
import lifeAxios from '../../utils/lifeAxios';
import { add_analytics } from "../gtagFunction.js";

/* Initial State for profile */
const initialState = {
  profiles: [],
  profile: {},
  loading: false,
  error: '',
  profileLoading: false,
};
/* For Getting Single Profile */
export const getProfile = createAsyncThunk(
  'profile/getProfile',
  async (profileId, { rejectWithValue }) => {
    try {
      const response = await lifeAxios(localStorage.getItem('jwt')).get(
        `/profiles/${profileId}`
      );
      return response.data.data.data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);
/* For Create Profile */
export const createProfile = createAsyncThunk(
  'profile/createProfile',
  async (formData, { rejectWithValue }) => {
    try {
      const response = await lifeAxios(localStorage.getItem('jwt')).post(
        `/profiles`,
        formData
      );
      try {
        add_analytics("Create-Profile", response, "none");
      }
      catch (ex) {
        console.log(ex);
      }

      return response.data.data.data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);
/*For Profile Visit*/
export const createProfileVisit = createAsyncThunk(
  'profile/createProfileVisit',
  async (profileId, { rejectWithValue }) => {
    try {
      const response = await lifeAxios(localStorage.getItem('jwt')).get(
        `/profiles/visit/${profileId}`
      );
      return response.data.data.data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }

);
/* For Qr Popup */
export const qrPopup = createAsyncThunk(
  'profile/qrPopup',
  async (formData, { rejectWithValue }) => {
    try {
      const response = await lifeAxios(localStorage.getItem('jwt')).post(
        `/profiles/qrPopup`,
        formData
      );
      return response.data.data.data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);
/* For Edit Profile */
export const editProfile = createAsyncThunk(
  'profile/editProfile',
  async ({ formData, profileId }, { rejectWithValue }) => {
    try {
      const response = await lifeAxios(localStorage.getItem('jwt')).patch(
        `/profiles/${profileId}`,
        formData
      );
      return response.data.data.data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

/* For Editing Profile's Gallery */
export const updateProfileGallery = createAsyncThunk(
  'profile/updateProfileGallery',
  async ({ formData, profileId }, { rejectWithValue }) => {
    try {
      const response = await lifeAxios(localStorage.getItem('jwt')).patch(
        `/profiles/profileGallery/${profileId}`,
        formData
      );
      return response.data.data.data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

/* For Getting All Profiles */
export const getAllProfiles = createAsyncThunk(
  'profile/getAllProfiles',
  async (_, { rejectWithValue }) => {
    try {
      const response = await lifeAxios(localStorage.getItem('jwt')).get(
        `/profiles`
      );
      return response.data.data.data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);
/* For Getting All Profiles of the user*/
export const getUserProfiles = createAsyncThunk(
  'profile/getUserProfiles',
  async (_, { rejectWithValue }) => {
    try {
      const response = await lifeAxios(localStorage.getItem('jwt')).get(
        `/profiles/user/myProfiles`
      );
      return response.data.data.data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);


// // eli (new) remove single index image from gallery
// ////////////
// export const RemoveGallery = createAsyncThunk(
//   'profile/RemoveGallery',
//   async ({ profileId, index }, { rejectWithValue }) => {
//     try {
//       const response = await lifeAxios(localStorage.getItem('jwt')).get(
//         `/profiles/${profileId}/galleryProperties?action=del&imageid=${index}`
//       );
//       if( response.status != 200 )
//       {
//         return rejectWithValue(response.message);
//       }
//       return response ; 
//     } catch (error) {
//       console.log(error)
//       return rejectWithValue(error.response.message);
//     }
//   }
//   ) ; 

export const calculateGallerySize = createAsyncThunk(
  'profile/calculateGallerySize',
  async ({ profileId }, { rejectWithValue }) => {
    try {
      const response = await lifeAxios(localStorage.getItem('jwt')).post(
        `/profiles/calculateGallerySize`,
        { profileId }
      );
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);
export const checkUserExists = createAsyncThunk(
  'profile/checkUserExists',
  async ({ firstName, lastName }, { rejectWithValue }) => {
    try {
      const response = await lifeAxios(localStorage.getItem('jwt')).post('profiles/checkProfile', { firstName, lastName });
      console.log("API Response:", response.data);
      return response.data.exists;
    } catch (error) {
      return rejectWithValue('Error checking user.');
    }
  }
);

export const QRSwap = createAsyncThunk(
  'profile/QRSwap',
  async ({ newId, oldId }, { rejectWithValue }) => {
    try {
      const response = await lifeAxios(localStorage.getItem('jwt')).post('profiles/QRSwap', { newId, oldId });
      console.log("API Response:", response.data);
      return response.data.exists;
    } catch (error) {
      return rejectWithValue('Error checking user.');
    }
  }
);

export const deleteById = createAsyncThunk(
  'profile/deleteById',
  async ({ deleteId }, { rejectWithValue }) => {
    try {
      const response = await lifeAxios(localStorage.getItem('jwt')).post('profiles/deleteById', { deleteId });
      console.log("API Response:", response.data);
      return response.data.exists;
    } catch (error) {
      return rejectWithValue('Error checking user.');
    }
  }
);

export const addOrganization = createAsyncThunk(
  'profile/addOrganization',
  async ({ profileId, organizationId }, { rejectWithValue }) => {
    try {
      console.log(profileId, organizationId);
      const response = await lifeAxios(localStorage.getItem('jwt')).post('/profiles/addOrganization', {
        profileId,
        organizationId,
      });
      console.log('API Response:', response.data);
      return response.data;
    } catch (error) {
      console.error('Error adding organization:', error);
      return rejectWithValue('Failed to add organization.');
    }
  }
);

export const removeOrganization = createAsyncThunk(
  'profile/removeOrganization',
  async ({ profileId, organizationId }, { rejectWithValue }) => {
    try {
      const response = await lifeAxios(localStorage.getItem('jwt')).post('/profiles/removeOrganization', {
        profileId,
        organizationId,
      });
      console.log('API Response:', response.data);
      return response.data;
    } catch (error) {
      console.error('Error removing organization:', error);
      return rejectWithValue('Failed to remove organization.');
    }
  }
);


/*  For Searching Profiles */
export const searchProfiles = createAsyncThunk(
  'profile/searchProfiles',
  async (searchQuery, { rejectWithValue }) => {
    try {
      const response = await lifeAxios(localStorage.getItem('jwt')).get(
        `/profiles/searchProfiles?search=${encodeURI(searchQuery)}`
      );
      return response.data.data.data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);
/* Add User to profile */
export const addUser = createAsyncThunk(
  'profile/addUser',
  async ({ formData, showNotification }, { rejectWithValue, dispatch }) => {
    try {
      const response = await lifeAxios(localStorage.getItem('jwt')).patch(
        `/profiles/${formData.profileId}/add`,
        formData
      );

      const user = response.data.data.data[formData.field].find(
        (user) => user._id === formData.userId
      );
      let message = '';
      if (formData.field === 'friendRequests')
        message = 'בקשת חברות נשלחה בהצלחה';
      else if (formData.field === 'profileAdmins')
        message = `${user.organizationName || user.firstName
          } קיבל.ה הרשאת מנהל.ת`;
      else if (formData.field === 'profileFriends')
        message = `${user.organizationName || user.firstName} עכשיו חבר.ה`;

      showNotification(
        'success',
        message,
        formData.field !== 'friendRequests' && {
          ...user,
          field: formData.field,
        }
      );

      dispatch(getProfile(formData.profileId));
      return response.data.data.data;
    } catch (error) {
      showNotification('error', error.response.data.message);
      return rejectWithValue(error.response.data.message);
    }
  }
);
/* Remove User to profile */
export const removeUser = createAsyncThunk(
  'profile/removeUser',
  async ({ formData, showNotification }, { rejectWithValue, dispatch }) => {
    try {
      const response = await lifeAxios(localStorage.getItem('jwt')).patch(
        `/profiles/${formData.profileId}/remove`,
        formData
      );
      dispatch(getProfile(formData.profileId));
      return response.data.data.data;
    } catch (error) {
      showNotification('error', error.response.data.message);
      return rejectWithValue(error.response.data.message);
    }
  }
);

/* Send Memorial Event */
export const memorialEvent = createAsyncThunk(
  'profile/memorialEvent',
  async (formData, { rejectWithValue }) => {
    try {
      const response = await lifeAxios(localStorage.getItem('jwt')).post(
        `/profiles/memorialEvent`,
        formData
      );

      return response.data.data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

/* Get Memorial Data */
export const getMemorialEvent = createAsyncThunk(
  'profile/getMemorialEvent',
  async (profileId, { rejectWithValue }) => {
    try {
      const response = await lifeAxios(localStorage.getItem('jwt')).get(
        `/profiles/memorialEvent/${profileId}`
      );
      return response.data.data.data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

const profileSlice = createSlice({
  name: 'profile',
  initialState,
  extraReducers: (builder) => {
    builder.addCase(calculateGallerySize.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(calculateGallerySize.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(calculateGallerySize.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
    builder.addCase(addOrganization.pending, (state) => {
      state.loading = true;
      state.error = null;
    })
    builder.addCase(addOrganization.fulfilled, (state, action) => {
      state.loading = false;
      state.profile = action.payload; // Update the state with the new profile data
    })
    builder.addCase(addOrganization.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    })
    // Remove Organization
    builder.addCase(removeOrganization.pending, (state) => {
      state.loading = true;
      state.error = null;
    })
    builder.addCase(removeOrganization.fulfilled, (state, action) => {
      state.loading = false;
      state.profile = action.payload; // Update the state with the updated profile data
    })
    builder.addCase(removeOrganization.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
    builder.addCase(checkUserExists.pending, (state) => {
      state.loading = true;
      state.userExists = false; // Reset userExists before making the request
    });
    builder.addCase(checkUserExists.fulfilled, (state, action) => {
      state.loading = false;
      state.userExists = action.payload; // true/false if user exists
    });
    builder.addCase(checkUserExists.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload; // Set error message if request fails
    });
    builder.addCase(QRSwap.pending, (state) => {
      state.isLoading = true;
      state.success = false;
      state.error = null;
    })
    // Fulfilled state
    builder.addCase(QRSwap.fulfilled, (state, action) => {
      state.isLoading = false;
      state.success = true;
      state.error = null;
      // You can also use action.payload if you need additional data from the API
    })
    // Rejected state
    builder.addCase(QRSwap.rejected, (state, action) => {
      state.isLoading = false;
      state.success = false;
      state.error = action.payload || 'An unknown error occurred.';
    });
    /* Single Profile */
    builder.addCase(getProfile.pending, (state, action) => {
      state.profile = {};
      state.profileLoading = true;
    });
    builder.addCase(getProfile.fulfilled, (state, action) => {
      state.profile = action.payload;
      state.profileLoading = false;
    });
    builder.addCase(getProfile.rejected, (state, action) => {
      state.profile = {};
      state.error = action.payload;
      state.profileLoading = false;
    });
    /* All Profiles */
    builder.addMatcher(
      isAnyOf(
        getAllProfiles.pending,
        getUserProfiles.pending,
        searchProfiles.pending
      ),
      (state, action) => {
        state.profiles = [];
        state.profileLoading = true;
      }
    );
    builder.addMatcher(
      isAnyOf(
        getAllProfiles.fulfilled,
        getUserProfiles.fulfilled,
        searchProfiles.fulfilled
      ),
      (state, action) => {
        state.profiles = action.payload;
        state.profileLoading = false;
      }
    );
    builder.addMatcher(
      isAnyOf(
        getAllProfiles.rejected,
        getUserProfiles.rejected,
        searchProfiles.rejected
      ),
      (state, action) => {
        state.profiles = [];
        state.error = action.payload;
        state.profileLoading = false;
      }
    );
    /* Rest Builder */
    builder.addMatcher(
      isAnyOf(
        addUser.pending,
        removeUser.pending,
        createProfile.pending,
        editProfile.pending,
        memorialEvent.pending,
        qrPopup.pending
      ),
      (state) => {
        state.loading = true;
      }
    );
    builder.addMatcher(
      isAnyOf(
        addUser.fulfilled,
        removeUser.fulfilled,
        createProfile.fulfilled,
        editProfile.fulfilled,
        memorialEvent.fulfilled,
        qrPopup.fulfilled
      ),
      (state) => {
        state.loading = false;
      }
    );
    builder.addMatcher(
      isAnyOf(
        addUser.rejected,
        removeUser.rejected,
        createProfile.rejected,
        editProfile.rejected,
        memorialEvent.rejected,
        qrPopup.rejected
      ),
      (state, action) => {
        state.loading = false;
        state.error = action.payload;
      }
    );
  },
});

export default profileSlice.reducer;
