import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getUserById } from '../../features/user/userSlice';

const FindUser = ({ id }) => {
  const dispatch = useDispatch();
  const [user, setUser] = useState(null);

useEffect(() => {
  const fetchUser = async () => {
    try {
      if (id) {
        const userData = await dispatch(getUserById(id)).unwrap();
        setUser(userData);
      }
    } catch (error) {
      console.error("Error fetching user:", error);
    }
  };
  fetchUser();
}, [dispatch, id]);

return (
  <div>
    {user ? `User Name: ${user.firstName} ${user.lastName}` : 'User not found'}
  </div>
);

};

export default FindUser;
