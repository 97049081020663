import React, { useState } from 'react';
import classes from './MemorialDayModal.module.css';
import cross from '../assets/candleflower-modal-img/x.png';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import InputError from '../components/InputError/InputError';
import {
  getMemorialEvent,
  memorialEvent,
} from '../features/profile/profileSlice';
import { useDispatch, useSelector } from 'react-redux';
import { Modal } from 'react-bootstrap';
import { hideModal } from '../features/modal/modalSlice';
import alertCreator from '../utils/alertCreator';
import { saveAs } from 'file-saver';
import Button from '../components/UI/Button/Button';
import toastCreator from '../utils/toastifyCreator';
import { useEffect } from 'react';
import memorialImage from '../assets/memorial-img/text.svg';

//Form validation schema
const schema = yup.object().shape({
  opening: yup.string(),
  hour: yup.string(),
  date: yup.string().required('תאריך האירוע הינו חובה'),
  location: yup.string().required('מיקום האירוע הינו חובה'),
  free: yup.string(),
  image: yup.mixed(),
  qrCheck: yup.boolean(),
});
const MemorialDayModal = ({ isOwnerOrAdmin, profile }) => {
  const [memorial, setMemorial] = useState({});
  const { memorialModal } = useSelector((state) => state.modal);
  const { loading } = useSelector((state) => state.profile);
  const [showMediaShare, setShowMediaShare] = useState(false);
  const [showDownloadFile, setShowDownloadFile] = useState(false);
  const [showPreview, setShowPreview] = useState(false);
  const [fileUrl, setFileUrl] = useState(profile.memorialPng);
  const [showDefaultPreview, setShowDefaultPreview] = useState(false);
  const dispatch = useDispatch();
  const [memorialImg, setMemorialImg] = useState('');
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({ resolver: yupResolver(schema) });

  const onChangeImage = (event) => {
    if (event.target.files[0]) {
      setMemorialImg(URL.createObjectURL(event.target.files[0]));
    }
  };

  useEffect(() => {
    (async () => {
      const response = await dispatch(getMemorialEvent(profile._id));
      setMemorial(response.payload);
      setMemorialImg(response.payload?.memorialImg);
      reset({ ...response.payload, date: response.payload?.date.slice(0, 10) });
    })();
  }, [dispatch, profile._id, reset, fileUrl]);
  const onSubmit = async (data, type) => {
    if (
      data && memorial &&
      data.opening === memorial.opening &&
      data.hour === memorial.hour &&
      data.date === memorial.date.slice(0, 10) &&
      data.location === memorial.location &&
      data.free === memorial.free &&
      data.qrCheck === memorial.qrCheck &&
      data.image.length === 0 &&
      type === 'preview'
    ) {
      setShowPreview(true);
      return setFileUrl(fileUrl);
    }

    setShowMediaShare(false);
    setShowDownloadFile(false);
    const formData = new FormData();
    if (type === 'whatsapp') {
      const whatsappText = `*משפחה%20חברים%20ומכרים,*%0aהנכם%20מוזמנים%20ל${data.opening}.%20האירוע%20יתקיים%20ב${data.location},%20בתאריך%20${data.date}%20בשעה%20${data.hour}.%0a%0a*בהזדמנות%20זו%20אנו%20מזימינים%20אתכם%20לעמוד%20המלא%20של%20${profile.firstName}%20${profile.lastName}%20ולעיין%20בחומרים%20מהעבר,%20לשתף%20סיפורים%20וזכרונות.*%0a*${window.location.href}*%0a%0a${data.free}%0a%0a*נשמח%20לראותכם*`;
      return (window.location.href = `https://api.whatsapp.com/send/?text=${whatsappText}`);
    }
    if (type === 'mail') formData.append('mail', true);

    if (type === 'pdf') formData.append('pdf', true);

    if (type === 'saveExit') formData.append('saveExit', true);

    if (type === 'png' || type === 'preview') formData.append('png', true);
    if (data.image.length > 0) {
      formData.append('memorialImg', data.image[0]);
    }
    formData.append('opening', data.opening);
    formData.append('hour', data.hour);
    formData.append('date', data.date);
    formData.append('location', data.location);
    formData.append('free', data.free);
    formData.append('qrCheck', data.qrCheck);
    formData.append('profileId', profile._id);

    const { payload } = await dispatch(memorialEvent(formData));
    reset();
    if (type === 'preview') {
      setShowPreview(true);
      setFileUrl(payload.data);
    } else if (type === 'pdf') {
      saveAs(payload.data, 'memorial.pdf');
    } else if (type === 'png') {
      saveAs(payload.data, 'memorial.png');
    } else if (type === 'mail') {
      alertCreator({
        icon: 'success',
        title: 'אימייל נשלח לחברי הפרופיל',
      });
    } else if (type === 'saveExit') {
      alertCreator({
        icon: 'success',
        title: 'האירוע נשמר',
      });
      setShowPreview('');
      dispatch(hideModal('memorialModal'));
    }
  };
  const downloadFile = async (type) => {
    if (!profile.memorialPdf || !profile.memorialPng) {
      return toastCreator('error', 'עדיין לא נוצרה הזמנה לאירוע');
    } else if (type === 'pdf') {
      saveAs(profile.memorialPdf, 'memorial.pdf');
    } else if (type === 'png') {
      saveAs(profile.memorialPng, 'memorial.png');
    }
  };

  return (
    <React.Fragment>
      <Modal
        show={memorialModal}
        onHide={() => dispatch(hideModal('memorialModal'))}
        className="qr_modal"
        size={isOwnerOrAdmin ? 'lg' : 'sm'}
        centered
      >
        <Modal.Header closeButton className={classes.modal_header}>
          {/* {isOwnerOrAdmin && (
            <button className={classes.reset_btn}>איפוס</button>
          )} */}
          <Modal.Title className={`${classes.modal_title} ms-auto`}>
            {isOwnerOrAdmin ? 'יצירת הזמנה לאירוע אזכרה' : 'שיתוף ההזמנה'}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className={classes.modal_body}>
          <React.Fragment>
            {isOwnerOrAdmin ? (
              <React.Fragment>
                <form>
                  {showMediaShare && (
                    <React.Fragment>
                      <div
                        className={classes.overlay}
                        onClick={() => setShowMediaShare(false)}
                      ></div>
                      <div className={classes.share_container}>
                        <h3 className="text-center">שיתוף ההזמנה</h3>
                        <div className={classes.share}>
                          <button
                            className={classes.share_item}
                            type="button"
                            onClick={(e) =>
                              handleSubmit((data) =>
                                onSubmit(data, 'whatsapp')
                              )(e)
                            }
                          >
                            <img
                              src="https://res.cloudinary.com/lifecloud-qr/image/upload/v1673076644/f3535dc3f95e71506f7c80755610176c-removebg-preview_nmo5oj.png"
                              alt="WhatsApp"
                            />
                            <p>ווטסאפ</p>
                          </button>
                          <button
                            className={classes.share_item}
                            onClick={(e) =>
                              handleSubmit((data) => onSubmit(data, 'mail'))(e)
                            }
                          >
                            <img
                              src="https://res.cloudinary.com/lifecloud-qr/image/upload/v1673076644/Gmail-logo_dpkhad.png"
                              alt="Gmail"
                            />
                            <p>מייל</p>
                          </button>
                        </div>
                        <div className={classes.share_footer}>
                          <p>*שיתוף במייל ישלח את ההזמנה לכל חברי העמוד</p>
                          <p>
                            **שיתוף בווטסאפ יעביר אתכם לאפליקציה עם הודעת הזמנה
                            מוכנה
                          </p>
                        </div>
                      </div>
                    </React.Fragment>
                  )}

                  {showDownloadFile && (
                    <React.Fragment>
                      <div
                        className={classes.overlay}
                        onClick={() => setShowDownloadFile(false)}
                      ></div>
                      <div className={classes.share_container}>
                        <h3 className="text-center">הורדה כקובץ</h3>
                        <div className={classes.share}>
                          <button
                            className={classes.share_item}
                            onClick={(e) =>
                              handleSubmit((data) => onSubmit(data, 'png'))(e)
                            }
                          >
                            <img
                              src="https://res.cloudinary.com/lifecloud-qr/image/upload/v1673092278/png_pd39xr.png"
                              alt="png"
                            />
                            <p>תמונה</p>
                          </button>
                          <button
                            className={classes.share_item}
                            onClick={(e) =>
                              handleSubmit((data) => onSubmit(data, 'pdf'))(e)
                            }
                          >
                            <img
                              src="https://res.cloudinary.com/lifecloud-qr/image/upload/v1673092379/pdf_gn8uve.png"
                              alt="pdf"
                            />
                            <p>מסמך</p>
                          </button>
                        </div>
                      </div>
                    </React.Fragment>
                  )}

                  {showDefaultPreview && (
                    <React.Fragment>
                      <div
                        className={classes.preview_overlay}
                        onClick={() => setShowDefaultPreview(false)}
                      ></div>
                      <div
                        className={`${classes.share_container} ${classes.preview_container}`}
                      >
                        <h3 className="text-center">הזמנה לדוגמא</h3>
                        <div className={classes.share}>
                          <img
                            src="https://res.cloudinary.com/lifecloud-qr/image/upload/v1674562525/memorial_24_1_cymuhh.png"
                            alt="file"
                            className="img-fluid"
                          />
                        </div>
                      </div>
                    </React.Fragment>
                  )}
                  {fileUrl && showPreview && (
                    <React.Fragment>
                      <div
                        className={classes.preview_overlay}
                        onClick={() => setShowPreview(false)}
                      ></div>
                      <div
                        className={`${classes.share_container} ${classes.preview_container}`}
                      >
                        <h3 className="text-center">תצוגה מקדימה</h3>
                        <div className={classes.share}>
                          <img src={fileUrl} alt="file" className="img-fluid" />
                        </div>
                        <div
                          className={`row ${classes.button_container} justify-content-center gy-3`}
                        >
                          <div className="col-6">
                            <Button
                              loading={loading}
                              onClick={() => setShowPreview(false)}
                            >
                              חזרה לעריכה
                            </Button>
                          </div>
                          <div className="col-6">
                            <Button
                              loading={loading}
                              onClick={(e) =>
                                handleSubmit((data) =>
                                  onSubmit(data, 'saveExit')
                                )(e)
                              }
                            >
                              שמור וצא
                            </Button>
                          </div>
                          <div className="col-6">
                            <Button
                              onClick={() => setShowDownloadFile(true)}
                              type="button"
                              loading={loading}
                            >
                              הורדה כקובץ
                            </Button>
                          </div>
                          <div className="col-6">
                            <Button
                              onClick={() => setShowMediaShare(true)}
                              type="button"
                              loading={loading}
                            >
                              שליחה לחברים
                            </Button>
                          </div>
                        </div>
                      </div>
                    </React.Fragment>
                  )}
                  <div className={`row`}>
                    <h6
                      className={`${classes.modal_sub_title} m-auto`}
                      dir="rtl"
                    >
                      מלא את השדות הרלוונטים ליצירת הזמנה{' '}
                    </h6>
                    <hr className={classes.hr} />
                    <div className="col-12">
                      <div className={classes.input_container} style={{ display: 'flex', alignItems: 'center' }}>
                        <input
                          type="text"
                          className="form-control"
                          placeholder={`הוסף משפט פתיחה...`}
                          {...register('opening')}
                        />
                        <label
                          style={{
                            position: 'relative',
                            top: '50%',
                            transition: '0.2s ease',
                            marginLeft: '10px',
                            fontSize: '16px',
                            color: '#1a4e74',
                            fontWeight: '200',
                          }}
                          className="floating-label"
                        >
                          :כותרת
                        </label>
                      </div>
                    </div>
                    <div className="col-12 col-md-6">
                      <div className={classes.input_container}>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="מיקום"
                          {...register('location')}
                        />
                        <InputError
                          className="text-center"
                          error={errors.location?.message}
                        />
                      </div>
                    </div>
                    <div className="col-6 col-md-3">
                      <div className={classes.input_container}>
                        <input
                          type="date"
                          className="form-control"
                          placeholder="תאריך"
                          {...register('date')}
                        />
                        <InputError
                          className="text-center"
                          error={errors.date?.message}
                        />
                      </div>
                    </div>
                    <div className="col-6 col-md-3">
                      <div className={classes.input_container}>
                        <input
                          type="time"
                          className="form-control"
                          placeholder="שעה"
                          {...register('hour')}
                        />
                      </div>
                    </div>
                    <div className="col-12">
                      <div className={classes.input_container} style={{ position: 'relative', display: 'flex' }}>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="טקסט חופשי"
                          {...register('free')}
                        />
                        <img
                          src={memorialImage}
                          alt="Memorial Day"
                          style={{
                            maxWidth: '35px',  // Limit the max width of the image
                            maxHeight: '35px',
                            width: '100%',
                            height: 'auto',
                            transition: '0.2s ease',
                            transform: 'translate(0, 35%)'
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row justify-content-center">
                    <div className="col-md-6">
                      <div className="col-12">
                        <div className="position-relative">
  {
    memorialImg ? (
      <div className="image-container" style={{ position: 'relative' }}>
        <img
          src={memorialImg}
          alt="QR"
          className={`${classes.qr_img}`}
          style={{ width: '100%', height: 'auto' }}
        />
        <div className="button-container" style={{ position: 'absolute', bottom: '10px', left: '50%', transform: 'translateX(-50%)', display: 'flex', gap: '10px' }}>
          {/* Change button triggers the file input */}
          <button
            className="btn-change"
            style={{
              width: '100px',
              fontWeight: '400',
              fontSize: '18px',
              background: 'rgba(0,0,0,0.01)',
              color: 'white',
              border: '0px',
              transition: 'border 0.3s ease'
            }}
            onClick={(e) => {
              e.preventDefault(); 
              document.getElementById('image').click(); 
            }}
          >
            החלפת תמונה
          </button>
          <p
            style={{
              padding: '5px 10px',
              fontWeight: '400',
              fontSize: '18px',
              background: 'rgba(0,0,0,0.01)',
              color: 'white',
              border: '0px',
              transition: 'border 0.3s ease'
            }}
          >
            |
          </p>
          <button
            className="btn-delete"
            style={{
              padding: '5px 10px',
              fontWeight: '400',
              fontSize: '18px',
              background: 'rgba(0,0,0,0.01)',
              color: 'white',
              border: '0px',
              transition: 'border 0.3s ease'
            }}
            onClick={(e) => {
              e.preventDefault(); 
              setMemorialImg(null); 
            }}
          >
            מחיקה
          </button>
        </div>
      </div>
    ) : (
      <label htmlFor="image" className="w-100">
        <img
          src={'https://res.cloudinary.com/lifecloud-qr/image/upload/v1675781613/Group_258_2_cebyui.png'}
          alt="QR"
          className={`${classes.qr_img}`}
        />
      </label>
    )
  }
  <input
    id="image"
    type="file"
    className="d-none"
    accept="image/*"
    {...register('image', {
      onChange: onChangeImage, 
    })}
  />
</div>
                      </div>
                      {/* <div className="col-md-12 mt-md-5 mt-3">
                      <div className={`${classes.input_container}`}>
                        
                        
                      </div>
                    </div> */}

                      <div className="col-12">
                        <div
                          className={`d-flex justify-content-center align-items-center py-md-4 pt-2 ${classes.qr_order}`}
                        >
                          <p dir="rtl">סמן להוספת QR להזמנה</p>
                          <img
                            src="https://res.cloudinary.com/lifecloud-qr/image/upload/v1672380441/Dead-profile-qr_brztwz.png"
                            alt="QR"
                          />
                          <div className="form-check ps-5">
                            <input
                              style={{borderColor: '#1a4e74', borderWidth: '1px'}}
                              className={`form-check-input ${classes.checkbox}`}
                              type="checkbox"
                              {...register('qrCheck')}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr className={classes.hr} />
<div className={`row ${classes.button_container} gy-3 justify-content-center`}>
  <div className="col-md-6 col-6">
    <Button
      loading={loading}
      onClick={(e) =>
        handleSubmit((data) => onSubmit(data, 'saveExit'))(e)
      }
    >
      שמור וצא
    </Button>
  </div>
  <div className="col-md-6 col-6">
    <Button
      loading={loading}
      onClick={(e) =>
        handleSubmit((data) => onSubmit(data, 'preview'))(e)
      }
    >
      תצוגה מקדימה
    </Button>
  </div>

  <div className="col-md-6 col-6">
    <Button
      onClick={() => setShowDownloadFile(true)}
      type="button"
      loading={loading}
    >
      הורדה כקובץ
    </Button>
  </div>
  <div className="col-md-6 col-6">
    <Button
      onClick={() => setShowMediaShare(true)}
      type="button"
      loading={loading}
    >
      שליחה לחברים
    </Button>
  </div>
</div>

                </form>
                <img
                  src="https://res.cloudinary.com/lifecloud-qr/image/upload/v1675321036/Vector_2_kgwfw0.png"
                  alt="Cloud"
                  className={classes.cloud_img}
                />
              </React.Fragment>
            ) : (
              <div className={classes.share}>
                <button
                  className={classes.share_item}
                  onClick={() => downloadFile('png')}
                >
                  <img
                    src="https://res.cloudinary.com/lifecloud-qr/image/upload/v1673092278/png_pd39xr.png"
                    alt="png"
                  />
                  <p>תמונה</p>
                </button>

                <button
                  className={classes.share_item}
                  onClick={() => downloadFile('pdf')}
                >
                  <img
                    src="https://res.cloudinary.com/lifecloud-qr/image/upload/v1673092379/pdf_gn8uve.png"
                    alt="pdf"
                  />
                  <p>מסמך</p>
                </button>
              </div>
            )}
          </React.Fragment>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
};

export default MemorialDayModal;
