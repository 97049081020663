/* Add tag to google analytics
   kicdev team
   27/02/2023 e.s. 
   see alsi public/index.html 
   to check replace the google tag to the cloud9 one line 107 'G-CMH3JPTTKF'
   action : login , signin , ... , 
   response: response object user data
   IdentificationMethod1: email / facebook / google 
*/
// window.dataLayer = window.dataLayer || [];
export const add_analytics = (action1, response1, IdentificationMethod1) =>
{
  try {
    window.dataLayer.push({
      action: action1,
      datetime: response1.data.data.user.updatedAt,
      userid: response1.data.data.user._id,
      userfulename: response1.data.data.user.firstName + " " + response1.data.data.user.lastName,
      IdentificationMethod: IdentificationMethod1,
      pagepath: String(document.location)
    });
  }
  catch (error) {
    console.error('Error in add_analytics', error);
  }
}