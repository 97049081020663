import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { hideModal } from '../features/modal/modalSlice';
import { showModal } from '../features/modal/modalSlice';
import classes from './FacebookShareModal.module.css';
import Button from '../components/UI/Button/Button';
import SocialLogin from '../components/SocialLogin/SocialLogin';
import ShareToPic from '../components/ShareToPic/ShareToPic'; 


import cross from '../assets/candleflower-modal-img/x.png';
import profileImageDemo from '../assets/candleflower-modal-img/candle.png';
import google from '../assets/candleflower-modal-img/googleLogo.png';
import facebook from '../assets/candleflower-modal-img/facebookLogo.png';
import gmail from '../assets/candleflower-modal-img/gmailLogo.png';
import prfilepic_a from '../assets/candleflower-modal-img/profilesamps/prfilepic_a.jpeg';
import prfilepic_b from '../assets/candleflower-modal-img/profilesamps/prfilepic_b.jpeg';
import prfilepic_c from '../assets/candleflower-modal-img/profilesamps/prfilepic_c.jpeg';
import prfilepic_z from '../assets/candleflower-modal-img/profilesamps/prfilepic_z.jpeg';

import friends from '../assets/facebookshare-modal-img/friends.png';
import fromBack from '../assets/facebookshare-modal-img/from-back.png';
import flowers from '../assets/facebookshare-modal-img/flowers.png';
import face from '../assets/facebookshare-modal-img/face.png';
import whats from '../assets/facebookshare-modal-img/whats.png';
import qr from '../assets/facebookshare-modal-img/qr.svg';
import insta from '../assets/facebookshare-modal-img/insta.png';


const FacebookShareModal = ({  }) => {

  return (
    <div
      className={`modal fade  ${classes.modalWrapper}`}
      id="facebookShareModal"
      tabIndex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div className={`modal-dialog modal-dialog-centered ${classes.facebookModal}`}>
        <div className={`modal-content  ${classes.modal_content}`}>
          <div className={ `modal-header justify-content-between ${classes.modal_header}` }  >
            <button
              type="button"
              className={`btn-close btn-close-white m-0  ${classes.btn_close}`}
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button> 
            
             <div className="m-auto">
              <h5  className={`${classes.header_title} m-auto`} >
                  שיתוף 
                <b>  עמוד  </b>
              </h5>
            </div>
           
          </div>
             
            <div  className={`${classes.facepostwrapper} m-auto`}  >
               
                <div  className={`${classes.facepostimage} m-auto`}  >
                    <img src={ friends } />
                
                </div>
                <div  className={`${classes.facecircleimg} m-auto`} >
                     <img src={ fromBack } />
                </div>
                 <div className={`${classes.bottomContent} `} >
                 <h3 >
                   ישראל ישראלי ז״ל
                 </h3>
                 <h4>
                   12.6.2022  -  12.10.1975
                 </h4>
                   <img src={ flowers } />
                 </div>
                 
                 <div className={`${classes.textBottom}`} >
                    <h4>
                      זוכרים לעד ומנציחים את הסיפור של 
                    <b>  ישראל ישראלי   </b>
                    </h4>
                    
                      <div className={` ${classes.profilesContain} `} >
                       <div className={` ${classes.profileImage} `} >
                          <img src={prfilepic_a} />
                       </div>
                        <div className={` ${classes.profileImage} `} >
                          <img src={prfilepic_b} />
                       </div>
                        <div className={` ${classes.profileImage} `} >
                          <img src={prfilepic_c} />
                       </div>
                        <div className={` ${classes.profileImage} `} >
                          <img src={prfilepic_z} />
                       </div>
                         <div className={` ${classes.profileImageEmpty}  ${classes.profileImage} `} >
                           <span>
                            ?אתם
                           </span>
                     </div>
                </div>
                
                    <span >
                    הצטרפו 
                   <b> ללא עלות  </b>
                    ל-34 חברים שכבר בקהילה לזכר ישראל
                    
                    </span>
                 </div>
            
            </div>
            
            <div  className={` ${classes.shareBtnsArea} `} > 
                   <div className={` ${classes.rowGuest} `}>
                   
                   
                    <div className={` ${classes.desktopView} `}  >
                     <div className={` ${classes.oneBtnWrapper} `}>
                         <div className={` ${classes.oneBtn} `}>
                            <img src={ face } />
                         </div>
                        <h3>
                         סטורי
                        </h3>
                     </div>
                     
                    
                      <div className={` ${classes.oneBtnWrapper} `}>
                         <div className={` ${classes.oneBtn} `}>
                            <img src={ face } />
                         </div>
                        <h3>
                         הודעה
                        </h3>
                     </div>
                     
                       <div className={` ${classes.oneBtnWrapper} `}>
                         <div className={` ${classes.oneBtn} `}>
                            <img src={ face } />
                         </div>
                        <h3>
                         פוסט
                        </h3>
                     </div>
                     
                     
                       <div className={` ${classes.oneBtnWrapper} `}>
                         <div className={` ${classes.oneBtn} `}>
                            <img src={ whats } />
                         </div>
                        <h3>
                         הודעה
                        </h3>
                     </div>
                     
                      <div className={` ${classes.oneBtnWrapper}  ${classes.qrWrapper}  `}>
                         <div className={` ${classes.oneBtn} `}>
                            <img src={ qr } />
                         </div>
                        <h3>
                          QR הורדת
                        </h3>
                     </div>
                     
                     </div>
                     
                       <div className={` ${classes.mobileView} `}  >
                       
                        <div className={` ${classes.oneBtnWrapper} `}>
                         <div className={` ${classes.oneBtn} `}>
                            <img src={ face } />
                         </div>
                        <h3>
                         סטורי
                        </h3>
                     </div>
                     
                    
                      <div className={` ${classes.oneBtnWrapper} `}>
                         <div className={` ${classes.oneBtn} `}>
                            <img src={ face } />
                         </div>
                        <h3>
                         הודעה
                        </h3>
                     </div>
                     
                       <div className={` ${classes.oneBtnWrapper} `}>
                         <div className={` ${classes.oneBtn} `}>
                            <img src={ face } />
                         </div>
                        <h3>
                         פוסט
                        </h3>
                     </div>
                       
                          <div className={` ${classes.oneBtnWrapper} `}>
                            <div className={` ${classes.oneBtn} `}>
                            <img src={ insta } />
                         </div>
                        <h3>
                         סטורי
                        </h3>
                     </div>
                     
                    
                      <div className={` ${classes.oneBtnWrapper} `}>
                         <div className={` ${classes.oneBtn} `}>
                            <img src={ insta } />
                         </div>
                        <h3>
                         הודעה
                        </h3>
                     </div>
                     
                       <div className={` ${classes.oneBtnWrapper} `}>
                         <div className={` ${classes.oneBtn} `}>
                            <img src={ insta } />
                         </div>
                        <h3>
                         פוסט
                        </h3>
                     </div>
                       
              
                     </div>
                   </div>
                   
                   
                    <div className={` ${classes.rowView} `}  > 
                           <Button>
                              QR הדפסת
                           </Button>
                   
                         <div className={` ${classes.mobileView} `}  >
                             <div className={` ${classes.oneBtnWrapper} ${classes.oneBtnWrapperRow}`}>
                                 <div className={` ${classes.oneBtn} `}>
                                    <img src={ whats } />
                                 </div>
                                <h3>
                                 הודעה
                                </h3>
                             </div>
                             
                              <div className={` ${classes.oneBtnWrapper}  ${classes.qrWrapper} ${classes.oneBtnWrapperRow}  `}>
                                 <div className={` ${classes.oneBtn} `}>
                                    <img src={ qr } />
                                 </div>
                                <h3>
                                  QR הורדת
                                </h3>
                             </div>
                           </div>
                   </div>
                   
            
            </div>
           
         
        </div>
      </div>
       <ShareToPic /> 
    </div>


  );
};

export default FacebookShareModal;
