import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getMemory } from '../../features/memory/memorySlice';
import './memoryGallery.css';

const MemoryGallery = ({ memoryId }) => {
  const dispatch = useDispatch();
  const [selectedImage, setSelectedImage] = useState(null); // State to track the selected image
  const { memory, loading } = useSelector((state) => state.memory);

  useEffect(() => {
    if (!memory) {
      dispatch(getMemory(memoryId));
    }
  }, [dispatch, memoryId, memory]);

  // If still loading, show loading text
  if (loading) {
    return <div>Loading gallery...</div>;
  }

  // If there's no memory or gallery, show "No images available"
  if (!memory || !memory.gallery || memory.gallery.length === 0) {
    return <div style={{fontSize: '20px'}}>אין תמונות בגלריית הזיכרון</div>;
  }

  // Filter the gallery to remove duplicate URLs
  const uniqueGalleryImages = [
    ...new Set(memory.gallery)
  ];

  // Handle image click (opens in modal)
  const openImageModal = (image) => {
    setSelectedImage(image);
  };

  // Close modal
  const closeModal = () => {
    setSelectedImage(null);
  };

  return (
    <div className="gallery-container">
      {uniqueGalleryImages.length ? (
        uniqueGalleryImages.map((imageLink, index) => (
          imageLink ? (
            <div key={index} className="gallery-item" onClick={() => openImageModal(imageLink)}>
              <img
                src={imageLink}
                alt={`Gallery item ${index + 1}`}
                className="gallery-image"
              />
            </div>
          ) : null // If imageLink is undefined or falsy, don't render anything
        ))
      ) : (
        <div style={{fontSize: '20px'}}>אין תמונות בגלריית הזיכרון</div>
      )}

      {/* Modal for the enlarged image */}
      {selectedImage && (
        <div className="modal" onClick={closeModal}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <img src={selectedImage} alt="Selected view" className="modal-image" />
          </div>
        </div>
      )}
    </div>
  );
};

export default MemoryGallery;
