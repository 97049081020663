import React, { useRef, useState, useEffect } from 'react';
import Button from '../../components/UI/Button/Button';
import classes from './Profile.module.css';
import formClasses from '../../pages/Form.module.css';
import uploadImg from '../../assets/profile-create-img/upload.svg';
import CenterContentLayout from '../../components/layout/CenterContentLayout';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { createProfile, checkUserExists } from '../../features/profile/profileSlice';
import ProfileAccordion from '../../components/ProfileAccordion/ProfileAccordion';
import toastCreator from '../../utils/toastifyCreator';
import alertCreator from '../../utils/alertCreator';
import { Link, useNavigate } from 'react-router-dom';
import { BsArrowLeft } from 'react-icons/bs';
import axios from 'axios';
import useGeoLocation from '../../utils/useGeoLocation';
import qs from 'qs';
import moment from 'moment';
import { saveAs } from 'file-saver';
import QRCode from 'qrcode';
import lifeAxios from '../../utils/lifeAxios';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { DatePicker, TextField } from '@mui/x-date-pickers/DatePicker';
import 'moment/locale/he';
import { getOrgProfByUser } from '../../features/organizationProfile/organizationProfileSlice';
import { useParams } from 'react-router-dom';

import {
  gregorianToHebDay,
  gregorianToHebMonth,
  gregorianToHebYear,
} from '../../utils/gregorianDate';
import location_icon from '../../assets/profile-create-img/location.png';
import { Helmet } from 'react-helmet-async';
import QrModal from '../../modals/QrModal';
import { hideModal, showModal } from '../../features/modal/modalSlice';
const ProfileCreate = () => {
  const navigate = useNavigate();
  const { organizationProfile } = useSelector(
    (state) => state.organizationProfile
  );
  const { user } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const { userExists, loading, error } = useSelector((state) => state.profile);
  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    watch,
    formState: { errors, isValid },
  } = useForm();
  const [profileImg, setProfileImg] = useState('');
  const [wallImg, setWallImg] = useState('');
  const [gallery, setGallery] = useState([]);
  const [privacy, setPrivacy] = useState('public');
  const [cemeteryLocation, setCemeteryLocation] = useState('');
  const getLatLng = (lat, lng) => {
    setCemeteryLocation(`${lat},${lng}`);
  };
  const { location, getGeoLocation, removeGeoLocation } = useGeoLocation({
    getLatLng,
  });
  const [exist, setExist] = useState(false);
  const { profileId } = useParams();

  const getCurrentGeoLocation = () => {
    getGeoLocation();
  };
  const removeCurrentGeoLocation = () => {
    removeGeoLocation();
    setCemeteryLocation('');
  };
  const normalQR = 140;
  const picQR = 220;
  const [lifeAxis, setLifeAxis] = useState([
    {
      axisImage: '',
      axisTitle: '',
      axisDate: '',
      axisDescription: '',
      imgUpload: false,
    },
    {
      axisImage: '',
      axisTitle: '',
      axisDate: '',
      axisDescription: '',
      imgUpload: false,
    },
    {
      axisImage: '',
      axisTitle: '',
      axisDate: '',
      axisDescription: '',
      imgUpload: false,
    },
    {
      axisImage: '',
      axisTitle: '',
      axisDate: '',
      axisDescription: '',
      imgUpload: false,
    },
  ]);
  const [accordion, setAccordion] = useState({
    profileAccordion: true,
    wallAccordion: false,
    dateAccordion: false,
    galleryAccordion: false,
    descriptionAccordion: false,
    axisAccordion: false,
    mapAccordion: false,
    zoomAccordion: false,
    privacyAccordion: false,
  });

  const toggleAccordion = (accordionName) => {
    setAccordion((prev) => ({
      ...prev,
      [accordionName]: true,
    }));
  };

  const onChangeProfilePicture = (event, setImg) => {
    if (event.target.files[0]) {
      const src = URL.createObjectURL(event.target.files[0]);
      setProfileImg(src);
      toggleAccordion('wallAccordion');
    }
  };
  const onChangeWallPicture = (event, setImg) => {
    if (event.target.files[0]) {
      const src = URL.createObjectURL(event.target.files[0]);
      setWallImg(src);
    }
  };
  const onChangeGalleryPicture = (event, setImg) => {
    if (event.target.files.length > 0) {
      setGallery((prev) => [...prev, ...event.target.files]);
      toggleAccordion('descriptionAccordion');
    }
  };
  const onRemoveGallery = (index) => {
    setGallery((prev) => prev.filter((img, i) => i !== index));
  };

  const onSubmit = async (data, type) => {
    dispatch(hideModal('qrFirstModal'));
    dispatch(hideModal('qrSecondModal'));

    const formData = new FormData();

    if (type === 'sendToEden') {
      formData.append('sendToEden', true);
      formData.append('qrText', data.qrText);
      formData.append('qrImg', data.qrImg[0]);
      formData.append('fullName', data.fullName);
      formData.append('address', data.address);
      formData.append('phone2', data.phone2);
    }
    if (type === 'sendProfileToEmail') {
      formData.append('sendProfileToEmail', true);
      formData.append('fullName', data.fullName);
      formData.append('address', data.address);
      formData.append('phone2', data.phone2);
    }
    if (type === 'sendQrToEmail') {
      formData.append('sendQrToEmail', true);
    }

    let filteredLifeAxis = lifeAxis.filter(
      (axis) =>
        axis.axisTitle ||
        axis.axisDate ||
        axis.axisDescription ||
        axis.axisImage
    );
    formData.append('originalUser', user._id);
    if (user.role === 'organization') {
      var organizationId;
      var id = user._id;
      const response = await dispatch(getOrgProfByUser({ userId: user._id }));
if (response.type === 'organizationProfile/getOrgProfByUser/fulfilled') {
  organizationId = response.payload;  // The organization ID is returned as the payload
  console.log("Organization ID:", organizationId);
} else if (response.type === 'organizationProfile/getOrgProfByUser/rejected') {
  console.error("Error fetching organization:", response.payload);
}
formData.append('organization', organizationId);
    }

    if (data.profileImg[0]) {
      formData.append('profileImg', data.profileImg[0]);
    }
    if (data.wallImg[0]) {
      formData.append('wallImg', data.wallImg[0]);
    }

    formData.append('firstName', data.firstName);
    formData.append('lastName', data.lastName);
    formData.append('gender', data.gender);
    formData.append('birthDate', data.birthDate);
    formData.append('degree', data.degree);
    formData.append('city', data.city);
    formData.append('hebBirthDate', data.hebBirthDate || '');

    formData.append('deathDate', data.deathDate || '');
    formData.append('memorialDate', data.memorialDate || '');
    formData.append('hebDeathDate', data.hebDeathDate || '');

    for (let i = 0; i < gallery.length; i++) {
      formData.append('gallery', gallery[i]);
    }
    formData.append('description', data.description);
    formData.append('lifeAxis', JSON.stringify(filteredLifeAxis));

    for (let i = 0; i < filteredLifeAxis.length; i++) {
      formData.append('axisImage', filteredLifeAxis[i].axisImage);
    }
    formData.append('cemetery', data.cemetery);
    formData.append('cemeteryLocation', cemeteryLocation);
    formData.append('privacy', privacy);

    const response = await dispatch(createProfile(formData));
    console.log("RESP: ", response);
    const profileId = response.payload._id;
    /* Payment for qr */
    localStorage.setItem('profileId', profileId);
    if (type === 'sendProfileToEmail') {
      const qrMode = "qrSend";
      const objectId = (await lifeAxios(localStorage.getItem('jwt')).post(
        `/purchases/create`,
        {
          profile: profileId,
          user: user._id,
          contact: user.organizationName || user.firstName,
          email: user.email,
          sum: normalQR,
          qrType: "qrSend"
        }
      )).data.data.data;
      window.location.assign(
        `https://direct.tranzila.com/icloud/iframenew.php?supplier=icloud&template=custom&u71=1&qrType=${qrMode}&sum=${normalQR}&currency=1&contact=${user.organizationName || user.firstName
        }&email=${user.email}&user=${user._id
        }&tranmode=A&pdesc=CandleOrFlower&type_code=320&tamal_lang=il&profile=${profileId}&doc_remark=docremark&purchase=${objectId}&ppnewwin=0`
      );
    }

    if (type === 'sendToEden') {
      const qrMode = "qrStatus";
      const objectId = (await lifeAxios(localStorage.getItem('jwt')).post(
        `/purchases/create`,
        {
          profile: profileId,
          user: user._id,
          contact: user.organizationName || user.firstName,
          email: user.email,
          sum: picQR,
          qrType: "qrStatus"
        }
      )).data.data.data;
      window.location.assign(
        `https://direct.tranzila.com/icloud/iframenew.php?supplier=icloud&template=custom&u71=1&qrType=${qrMode}&sum=${picQR}&currency=1&contact=${user.organizationName || user.firstName
        }&email=${user.email}&user=${user._id
        }&tranmode=A&pdesc=CandleOrFlower&type_code=320&tamal_lang=il&profile=${profileId}&doc_remark=docremark&purchase=${objectId}&ppnewwin=0`

      );
    }
    /* After Creating Profile Success Message */
    alertCreator({
      icon: 'success',
      title: 'הפרופיל נשמר בהצלחה, כעת ניתן לשתפו עם חברים',
    }).then(async () => {
      if (type === 'sendQrToEmail') {
        const genImg = await QRCode.toDataURL(
          `${window.location.href}/profile/${profileId}`
        );
        saveAs(genImg, `${profileId}-profile-qr.png`);
        localStorage.removeItem('qrModal');
      }

      navigate(`/profile/${profileId}`);
    });
  };
  /* Genders */
  const radioOptions = [
    {
      value: 'other',
      name: 'other',
      label: 'אחר',
    },
    {
      value: 'female',
      name: 'female',
      label: 'נ',
    },
    {
      value: 'male',
      name: 'male',
      label: 'ז',
    },
  ];
  const addAxisItem = () => {
    setLifeAxis([
      ...lifeAxis,
      { axisImage: '', axisTitle: '', axisDate: '', axisDescription: '' },
    ]);
  };

  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...lifeAxis];
    list[index][name] = value;
    setLifeAxis(list);
    toggleAccordion('mapAccordion');
  };

  const onChangeAxisImg = (event, index) => {
    if (event.target.files[0]) {
      const list = [...lifeAxis];
      list[index].axisImage = event.target.files[0];
      list[index].imgUpload = true;
      setLifeAxis(list);
    }
  };

  const removeAxisItem = (index) => {
    setLifeAxis((prev) => prev.filter((item, i) => i !== index));
  };
  const [autoDate, setAutoDate] = useState(true);
  const generateDate = async (event) => {
    const [year, month, day] = event.target?.value ? event.target.value.split('-') : event.split('-');
    toggleAccordion('galleryAccordion');
    const url = `https://www.hebcal.com/yahrzeit/?n1=Person1&t1=Memorial&d1=${day}&m1=${month}&y1=${year}&sX=off`;
    const options = {
      method: 'POST',
      headers: { 'content-type': 'application/x-www-form-urlencoded' },
      data: qs.stringify({
        cfg: 'json',
        v: 'yahzeit',
      }),
      url,
    };

    const res = await axios(options);
    const generatedDate = res.data.items.find((mem) =>
      moment(mem.date).isAfter(moment(new Date()))
    );
    const [genDay, genMonth, genYear] = generatedDate.hdate.split(' ');

    const hebDay = gregorianToHebDay(genDay);
    const hebMonth = gregorianToHebMonth(genMonth);
    const hebYear = gregorianToHebYear(genYear);

    setValue('memorialDate', generatedDate.date);
    setMemDay(generatedDate.date);
    setValue('hebDeathDate', `${hebDay} ${hebMonth} ${hebYear}`);
  };
  const submitButtonRef = useRef(null);
  const handleBirthDateChange = (event) => {
    const bday = moment(event?._d).format('YYYY-MM-DD');
    setValue("birthDate", bday);
  };

  const [memDay, setMemDay] = useState(undefined)

  const handleDeathDateChange = (event) => {
    const dday = moment(event?._d).format('YYYY-MM-DD');
    generateDate(dday);
    setValue("deathDate", dday);
  };

  const handleMemorialDateChange = (event) => {
    const dday = moment(event?._d).format('YYYY-MM-DD');
    setMemDay(dday);
    setValue("memorialDate", dday);
  };
  const handleBlur = () => {
    const firstName = watch('firstName');
    const lastName = watch('lastName');
    if (firstName && lastName) {
      dispatch(checkUserExists({ firstName, lastName }));
    }
  };
useEffect(() => {
    if (userExists) {
        setExist(true);
        
        const timer = setTimeout(() => {
            setExist(false);
        }, 3000); 
        return () => clearTimeout(timer);
    }
}, [userExists]);

  return (
    <React.Fragment>
    <Helmet>
    <title>LifeCloud | יצירת פרופיל </title>
      < meta
  property = "og:url"
  content = "https://lifecloud-qr.com/create-profile"
    />
    </Helmet>
    < CenterContentLayout cloudPos = "left-right" >
      <div className={ `container ${classes.profile_create} py-5` }>
        <div className={ `${classes.profile_header} text-center` }>
          <h2 className="page_title" > יצירת פרופיל </h2>
            < Link to = "/profile/62930e650fc791cf90ac210c" >
              <Button className={ classes.create_button_top }>
                !לחץ לפרופיל לדוגמא
                  </Button>
                  </Link>
                  </div>
                  < form className = "row" onSubmit = {handleSubmit(onSubmit)} >
                    <div className="col-lg-8 col-md-10 mx-auto" >
                      <ProfileAccordion
                isFilled={ getValues('profileImg')?.length }
  show = { accordion.profileAccordion }
  target = "profileImg"
  title = "תמונת פרופיל"
  modalText = "העלה תמונת פרופיל עבור הפרופיל שלך. תמונה זו תופיע בפרופיל שלך ובפרופילים של המשתמשים שלך."
    >
    <label htmlFor="profileImg" >
      <div className={ classes.upload }>
        <img
                      src={ profileImg || uploadImg }
  alt = "upload"
  className = {`${profileImg && classes.img_active}`
}
                    />
  </div>
  < h3 className = { classes.upload_button } > בחרו תמונה להעלאה </h3>
    < input
type = "file"
id = "profileImg"
className = "d-none"
accept = "image/*"
{...register('profileImg', {
  onChange: onChangeProfilePicture,
})
}
                  />
  </label>
  </ProfileAccordion>

  < ProfileAccordion
isFilled = { getValues('wallImg')?.length }
target = "wallImg"
title = "תמונת נושא"
modalText = "יש להעלות קובץ תמונה שיופיע כתמונה משנית בעמוד הפרופיל"
show = { accordion.wallAccordion }
  >
  <label htmlFor="wallImg" >
    <div className={ classes.upload }>
      <img
                      src={ wallImg || uploadImg }
alt = "upload"
className = {`${wallImg && classes.img_active}`}
                    />
  </div>
  < h3 className = {`${classes.upload_button} d-inline-block`}>
    בחרו תמונה להעלאה
      </h3>
      < input
type = "file"
id = "wallImg"
className = "d-none"
accept = "image/*"
{...register('wallImg', {
  onChange: onChangeWallPicture,
})
}
                  />
  </label>
  </ProfileAccordion>

  < div className = {`row ${classes.row_gap}`}>
    <div className="col-md-6 order-sm-0 order-1" >
      <input
            type="text"
placeholder = "*שם משפחה..."
className = {`form-control ${formClasses.input} ${classes.input}`}
{...register('lastName', { required: true }) }
onBlur = { handleBlur }
  />
  { errors.lastName && <small className="error"> שם המשפחה חובה!</ small >}
</div>
  < div className = "col-md-6 order-sm-1 order-0" >
    <input
            type="text"
placeholder = "*שם פרטי..."
className = {`form-control ${formClasses.input} ${classes.input} ${classes.name_input}`}
{...register('firstName', { required: true }) }
onBlur = { handleBlur }
  />
  { errors.firstName && <small className="error"> השם הפרטי חובה!</ small >}
    {exist && <small className="error">יש משתמש באותו השם!</small>}
</div>
  </div>
  < div className = {`row ${classes.row_gap}`}>
    <div className="col-md-6" >
      <div className={ formClasses.radio_container }>
      {
        radioOptions.map((option, index) => (
          <div className= { formClasses.radio_input } key = { index } >
          <input
                          type="radio"
                          id = { option.value }
                          className = { formClasses.radio }
                          value = { option.value }
                          { ...register('gender') }
          />
          <label htmlFor={ option.value } > { option.label } </label>
        </div>
        ))
      }
        < h3 className = { formClasses.radio_label } > מגדר * </h3>
          </div>
          </div>
          < div className = "col-md-6" style = {{ marginTop: '10px' }}>
            <LocalizationProvider dateAdapter={ AdapterMoment } locale = "he" >
              <DatePicker
                      slotProps={
  {
    textField: {
      placeholder: "ת. לידה לועזי",
        variant: 'standard',
          InputProps: { disableUnderline: true, style: { fontSize: '16px' } }
    }
  }
}
className = {`form-control ${formClasses.input} ${classes.input} ${classes.date_input}`}
onChange = { handleBirthDateChange }
value = { getValues("birthDate") ?moment(getValues("birthDate")) : null}
                      // value={moment(getValues("birthDate") ?? undefined)}
                    />
  </LocalizationProvider>

  </div>
  </div>

  < div className = {`row ${classes.row_gap}`}>
    <div className="col-6" >
      <input
                    type="text"
placeholder = "תואר..."
className = {`form-control ${formClasses.input} ${classes.input}`}
{...register('degree') }
                  />
  </div>
  < div className = "col-6" >
    <input
                    type="text"
placeholder = "*עיר..."
className = {`form-control ${formClasses.input} ${classes.input}`}
{...register(
  'city',
  { required: true },
  {
    onChange: () => toggleAccordion('dateAccordion'),
  }
)
}
                  />
{
  errors.city && (
    <small className="error" > עיר הוא שדה חובה! </small>
                  )
}
</div>
  </div>

  < ProfileAccordion
isFilled = { getValues('deathDate') }
target = "date"
title = "תאריך פטירה / אזכרה"
modalText = "יש למלא את תאריך הפטירה הלועזי בעמודה הימנית ומיד לאחר מכן, תאריך האזכרה יתמלא אוטומטית על ידי מערכתנו.

              במידה וישנם אילוצים לשינוי תאריך האזכרה, ניתן לסמן את תיבת הסימון מתחת לשדות המילוי ולערוך בעצמכם את התאריך המתקבל.
              "
show = { accordion.dateAccordion }
  >
  <div className="row gx-5" >
    <div className="col-6" >
      <h3
                      className={ `form-control ${classes.date_title} bg-transparent mb-4` }
                    >
  תאריך אזכרה{ ' ' }
</h3>
  </div>



  < div className = "col-6" >
    <h3
                      className={ `form-control ${classes.date_title} bg-transparent mb-4` }
                    >
  תאריך פטירה
    </h3>
    </div>

{/*    <ThemeProvider theme={theme}>
              </ThemeProvider>*/}
<div className="col-6" >
  <LocalizationProvider dateAdapter={ AdapterMoment } locale = "he" >
    <DatePicker
                        slotProps={
  {
    textField: {
      placeholder: "ת. אזכרה לועזי",
        variant: 'standard',
          InputProps: { disableUnderline: true, style: { fontSize: '16px' } }
    }
  }
}
className = {`form-control ${formClasses.input} ${classes.input} ${classes.date_input} `}
onChange = { handleMemorialDateChange }
value = { memDay?moment(memDay): null }
disabled = { autoDate }
  />
  </LocalizationProvider>
  </div>

  < div className = "col-6" >
    <LocalizationProvider dateAdapter={ AdapterMoment } locale = "he" >
      <DatePicker
                        slotProps={
  {
    textField: {
      placeholder: "ת. פטירה לועזי",
        variant: 'standard',
          InputProps: { disableUnderline: true, style: { fontSize: '16px' } }
    }
  }
}
className = {`form-control ${formClasses.input} ${classes.input} ${classes.date_input}`}
onChange = { handleDeathDateChange }
value = { getValues("deathDate") ?moment(getValues("deathDate")) : null}
                      />
  </LocalizationProvider>
  </div>
  < div className = "col-6" style = {{ paddingTop: "20px" }}>
    <input
                      type="text"
placeholder = "ת. פטירה עברי"
className = {`form-control ${formClasses.input} my-component-margin`}
{...register('hebDeathDate') }
disabled = { autoDate }
style = {{ backgroundColor: 'white', fontSize: '16px', color: 'rgba(0, 0, 0, 0.38)', padding: '1.2rem 3rem' }}
                    />
  </div>
  < div className = "col-md-12 my-5 d-flex justify-content-center align-items-center" >
    <h3
                      className={ `text-white my-0 fw-light fs-sm-6 ${classes.date_bottom_text}` }
                    >
  לעריכת התאריך האוטומטי שהתקבל סמנו תיבה זו
    </h3>
    < input
type = "checkbox"
className = {`ms-3 form-check-input text-center ${formClasses.check_box} ${classes.check_box}`}
onChange = {() => setAutoDate((prev) => !prev)}
                    />
  </div>
  < h5 dir = "rtl" className = { classes.teaser_text } >
                    * לסירוגין יש סטייה בתאריך המתקבל אוטומטית מאחר ותאריך הפטירה
                    הלועזי מחושב כלפני שקיעת אותו היום
  </h5>
  </div>
  </ProfileAccordion>

  < ProfileAccordion
isFilled = { getValues('gallery')?.length }
target = "gallery"
title = "גלריה"
modalText = "הגלריה מציגה אלבום מדיה של יקירכם, רצוי להעלות עד 8 קבצים בכל העלאה."
show = { accordion.galleryAccordion }
  >
  <label htmlFor="profile" >
    <div
                    className={ `${classes.upload} d-flex gap-4 justify-content-center flex-wrap` }
                  >
{
  gallery.length > 0 ? (
    gallery.map((img, i) => (
      <div key= { i } >
      <img
                            src={
      img.type.startsWith('video')
        ? 'https://res.cloudinary.com/lifecloud-qr/image/upload/v1672574174/photo-1611162616475-46b635cb6868_l2gzrj.avif'
        : URL.createObjectURL(img)
    }
                            alt = "upload"
                            className = { classes.img_active }
      />
      <p
                            className="text-white"
                            onClick = {() => onRemoveGallery(i)}
  >
  מחיקה
  </p>
  </div>
                      ))
                    ) : (
  <img src= { uploadImg } alt = "upload" />
                    )}
</div>

  < h3 className = { classes.upload_button } > בחרו קובץ להעלאה </h3>
    < h5 className = "text-white fw-lighter mt-3" >
      יש לבחור עד 8 קבצים בכל העלאה *
        </h5>
        < input
type = "file"
id = "profile"
multiple
className = "d-none"
accept = "image/*, video/*"
{...register('gallery', {
  onChange: onChangeGalleryPicture,
})
}
                  />
  </label>
  </ProfileAccordion>

  < ProfileAccordion
isFilled = { getValues('description') }
target = "description"
title = "סיפור חיים"
modalText = "ספרו קצת על יקיריכם שתרצו להנציח, שגם החברים יוכלו להכיר יותר, גם המשפחה ואפילו אלה שלא זכו להכיר."
show = { accordion.descriptionAccordion }
  >
  <textarea
                  {
    ...register('description', {
      onChange: () => toggleAccordion('axisAccordion'),
    })
  }
placeholder = "טקסט חופשי..."
className = {`form-control ${formClasses.input} ${formClasses.textarea} ${classes.input_focus} bg-transparent mb-4`}
                > </textarea>
  </ProfileAccordion>

  < ProfileAccordion
target = "lifeAxis"
title = "נקודות ציון"
modalText = "הוסיפו נקודות ציון חשובות בדרכם של יקיריכם אותם אתם מנציחים. תוכלו לספר על תקופות בחיים, אירועים חשובים ורגעים משמעותיים."
show = { accordion.axisAccordion }
isFilled = { Object.values(lifeAxis[0]).some((value) => value) }
  >
{
  lifeAxis.map((axis, i) => (
    <div
                    className= {`row justify-content-center align-items-center ${classes.life_axis}`}
key = { i }
  >
  <div className="col-md-2 order-md-1 order-5" >
    <p onClick={ () => removeAxisItem(i) } role = "button" >
      - הסר
      </p>
      </div>

      < div className = "col-md-4 order-md-2 order-4" >
        <div>
        <input
                          type="text"
placeholder = "ספרו על נקודת הציון..."
name = "axisDescription"
value = { axis.axisDescription }
onChange = {(e) => handleInputChange(e, i)}
className = {`form-control ${formClasses.input} bg-white mb-4`}
                        />
  </div>
  </div>
  < div className = "col-md-2 col-6 order-md-3 order-2" >
    <div>
    <input
                          type="text"
placeholder = "תאריך..."
onChange = {(e) => handleInputChange(e, i)}
name = "axisDate"
value = { axis.axisDate }
className = {`form-control ${formClasses.input} bg-white mb-4`}
                        />
  </div>
  </div>
  < div className = "col-md-2 col-6 order-md-4 order-3" >
    <div>
    <input
                          type="text"
placeholder = "כותרת..."
onChange = {(e) => handleInputChange(e, i)}
name = "axisTitle"
value = { axis.axisTitle }
className = {`form-control ${formClasses.input} bg-white mb-4`}
                        />
  </div>
  </div>
  < div className = "col-md-2 order-md-5 order-1" >
    <div className="my-3" >
      <label
                          htmlFor={ `axisImage${i}` }
className = { classes.upload_text }
  >
  <img
                            className={
  `${axis.axisImage && classes.img_active_lifeAxis
  }`
}
src = {
  axis.axisImage
    ? URL.createObjectURL(axis.axisImage)
    : uploadImg
}
alt = "upload"
  />
  <h5>העלאת מדיה </h5>
    < input
type = "file"
id = {`axisImage${i}`}
className = "d-none"
onChange = {(e) => onChangeAxisImg(e, i)}
                          />
  </label>
  </div>
  </div>
  </div>
                ))}
<div className={ classes.button_container }>
  <Button
                    type="button"
onClick = { addAxisItem }
className = { classes.add_axis }
  >
  +
  </Button>
  < p className = "d-md-none d-block" >
    הוסף עוד נקודות בסיפור חיים
      </p>
      </div>
      </ProfileAccordion>

      < ProfileAccordion
target = "map"
title = "מיקום קבר"
modalText = "בסעיף זה, ניתן להוסיף את מיקום הקבר המדוייק על ידי נעיצת סיכה בגוגל מפות ובנוסף גם להוסיף הכוונות הגעה לבית הקברות עצמו על ידי הוספת מיקום בוויז."
show = { accordion.mapAccordion }
isFilled = { getValues('cemetery') }
  >
  <div className={ `row gx-5 ${classes.map}` }>
    <div className="col-md-6 order-md-1 order-2" >
      {!location.loaded && (
        <Button
                        className={ `${classes.upload_button} w-100 mt-0 py-2 mb-3 d-flex align-items-center justify-content-center` }
type = "button"
onClick = { getCurrentGeoLocation }
disabled = {!getValues('cemetery')}
                      >
  <img
                          src={ location_icon }
alt = "location"
height = "25px"
className = "me-2"
  />
  נע��צת מיקומכם הנוכחי כמיקום הקבר
                      </Button>
                    )}
                    {location.loaded && location.coordinates && (
                      <Button
                        className={`${classes.upload_button} w-100 mt-0 py-2 mb-3 d-flex align-items-center justify-content-center`}
                        type="button"
                        onClick={removeCurrentGeoLocation}
                        disabled={!getValues('cemetery')}
                      >
                        <img
                          src={location_icon}
                          alt="location"
                          height="25px"
                          className="me-2"
                        />
                        מיקום נוכחי נוסף
                      </Button>
                    )}
                    {location.loaded && location.error && (
                      <Button
                        className={`${classes.upload_button} w-100 mt-0 py-2 mb-3 d-flex align-items-center justify-content-center`}
                        type="button"
                        onClick={getCurrentGeoLocation}
                        disabled={!getValues('cemetery')}
                      >
                        <img
                          src={location_icon}
                          alt="location"
                          height="25px"
                          className="me-2"
                        />
                        יש לאשר שימוש בשירותי מיקום בהגדרות המכשיר
                      </Button>
                    )}
                  </div>
                  <div className="col-md-6 order-md-2 order-1">
                    <input
                      type="text"
                      placeholder="שם בית הקברות"
                      className={`form-control ${formClasses.input} ${classes.input_focus}  bg-transparent mb-4`}
                      {...register('cemetery', {
                        onChange: () => toggleAccordion('privacyAccordion'),
                      })}
                    />
                  </div>
                </div>
              </ProfileAccordion>
              <ProfileAccordion
                target="privacy"
                title="פרטיות"
                modalText="יש לבחור את פרטיות הפרופיל החדש:
              ציבורי - פתוח לקהל הרחב ללא הגלבת תכנים
              פרטי - ניתן לראות תכנים רק על ידי אישור חברות
              "
                show={accordion.privacyAccordion}
                isFilled={privacy}
              >
                <div className="d-flex justify-content-center gap-4">
                  <Button
                    className={`${privacy === 'public' && classes.active_privacy
                      } ${classes.privacy_button}`}
                    onClick={() => setPrivacy('public')}
                    type="button"
                  >
                    פרופיל ציבורי
                  </Button>
                  <Button
                    className={`${privacy === 'private' && classes.active_privacy
                      } ${classes.privacy_button}`}
                    onClick={() => setPrivacy('private')}
                    type="button"
                  >
                    פרופיל פרטי
                  </Button>
                </div>
              </ProfileAccordion>

              <div
                className={`${classes.profile_create_button} d-flex justify-content-center`}
              >
                <Button
                  type="button"
                  onClick={() => navigate(-1)}
                  className={classes.submit}
                >
                  <BsArrowLeft className="me-2" />
                  חזרה
                </Button>
                <Button
                  className={`${isValid ? classes.submit : 'd-none'}`}
                  onClick={() => {
                  dispatch(showModal('qrFirstModal'))
                  }}
                  type="button"
                  loading={loading}
                >
                  שמירה
                </Button>
                <Button
                  ref={submitButtonRef}
                  type="submit"
                  className={`${!isValid ? classes.submit : 'd-none'}`}
                >
                  שמירה
                </Button>
              </div>
            </div>

            <QrModal
              register={register}
              handleSubmit={handleSubmit}
              onSubmit={onSubmit}
            />
          </form>
        </div>
      </CenterContentLayout>
    </React.Fragment>
  );
};

export default ProfileCreate;


    // {iOS?
    //                   <LocalizationProvider dateAdapter={AdapterMoment} locale="he">
    //               <DatePicker
    //                 slotProps={{
    //                   textField: {
    //                     placeholder:"ת. אזכרה לועזי",
    //                     variant: 'standard',
    //                     InputProps: { disableUnderline: true, style: { fontSize: '16px' } }
    //                   }
    //                 }}
    //                 className={`form-control ${formClasses.input} `}
    //                   {...register('deathDate', {
    //                     onChange: generateDate,
    //                   })}
    //               />
    //             </LocalizationProvider>
    //                 :
    //                 <input
    //                   type="date"
    //                   placeholder="ת. אזכרה עברי"
    //                   className={`form-control ${formClasses.input} `}
    //                   {...register('deathDate', {
    //                     onChange: generateDate,
    //                   })}
    //                 />
    //             }





//
// {iOS?
//                 <LocalizationProvider dateAdapter={AdapterMoment} locale="he">
//                   <DatePicker
//                     slotProps={{
//                       textField: {
//                         placeholder: "ת. אזכרה לועזי",
//                         variant: 'standard',
//                         InputProps: { disableUnderline: true, style: { fontSize: '16px' } }
//                       }
//                     }}
//                     onChange={handleDateChange}
//                     className={`form-control ${formClasses.input} ${classes.input} ${classes.date_input}`}
//                     {...register('birthDate')}
//                   />
//                 </LocalizationProvider>

//                   :
//                   <input
//                       type="text"
//                       placeholder="ת. אזכרה לועזי"
//                       className={`form-control ${formClasses.input} mb-3`}
//                       {...register('memorialDate')}
//                       disabled={autoDate}
//                     />
//                 }