import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import InputError from '../../components/InputError/InputError';
import { useDispatch, useSelector } from 'react-redux';
import { createCandleFlower, lightAllCandles } from '../../features/candleFlower/candleFlowerSlice';
import emailTextAndSubject from '../../utils/emailTextAndSubject';
import lifeAxios from '../../utils/lifeAxios';
import { createNotification } from '../../features/notification/notificationSlice';
import Swal from 'sweetalert2';
const moment = require('moment');


const LightCF = () =>
{
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.user);
  console.log(user);
  //Form validation schema
  const schema = yup.object().shape({
    user: yup.string().required('User ID is required'),
    profile: yup.string().required('User ID is required'),
    candles: yup.number(),
    flowers: yup.number(),
  });

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });

  //Form  submit handler
  const onSubmit = async (formData) =>
  {


    if (user.role !== 'admin')
      return alert(
        'You are not Admin, So please pay 💵 add light up candle and flower ☠'
      );

    var candles = formData["candles"];
    var flowers = formData["flowers"];
    formData["candlesFlowers"] = [];
    if (candles) formData["candlesFlowers"].push({ id: 7, type: 'candle', count: candles });
    if (flowers) formData["candlesFlowers"].push({ id: 1, type: 'flower', count: flowers });
    delete formData["candles"];
    delete formData["flowers"];
    if(formData.profile.trim() == "***") {
      const profileResponse = await lifeAxios(localStorage.getItem('jwt')).post(
        `/candleFlowers/lightAllCf`,
        formData
      );
      return;
    }
    if (!isOrganization) {
      dispatch(createCandleFlower(formData));

      const profileResponse = await lifeAxios(localStorage.getItem('jwt')).get(
        `/profiles/${formData.profile}`
      );
      const profile = profileResponse.data.data.data;

      const creatorResponse = await lifeAxios(localStorage.getItem('jwt')).get(
        `/users/${formData.user}`
      );
      const creator = creatorResponse.data.data.data;
      const receivers = [
        profile.originalUser._id,
        ...profile.profileAdmins.map((admin) => admin._id),
        ...profile.profileFriends.map((friend) => friend._id),
      ];

      const notification = {
        ...emailTextAndSubject(
          'candleFlower',
          {
            organizationName: profile.organizationName,
            firstName: profile.firstName,
            lastName: profile.lastName,
          },
          {
            organizationName: creator.organizationName,
            firstName: creator.firstName,
            lastName: creator.lastName,
          }
        ),
        creator: creator._id,
        receivers: receivers,
        url: `https://lifecloud-qr.com/profile/${formData.profile}`,
      };
      // await dispatch(createNotification(notification)); //TODO: Server notifications
    }
    else {
      formData["organizationProfile"] = formData["profile"];
      delete formData["profile"];
      const response = (await lifeAxios(localStorage.getItem('jwt')).post(
        `/candleFlowers/light-org-cf`,
        formData
      )).data.data.data;

      await Promise.all(response.map(async (profile) =>
      {
        const creatorResponse = await lifeAxios(localStorage.getItem('jwt')).get(
          `/users/${formData.user}`
        );
        const creator = creatorResponse.data.data.data;
        const receivers = [
          profile.originalUser,
          ...profile.profileAdmins,
          ...profile.profileFriends,
        ];
        // console.log("rec",receivers);
        
        const filteredRec = [...new Set(receivers.filter(rec=>rec!==creator._id))];
        // console.log(filteredRec);

        const notification = {
          ...emailTextAndSubject(
            'candleFlower',
            {
              organizationName: profile.organizationName,
              firstName: profile.firstName,
              lastName: profile.lastName,
            },
            {
              organizationName: creator.organizationName,
              firstName: creator.firstName,
              lastName: creator.lastName,
            }
          ),
          creator: creator._id,
          receivers: filteredRec,
          url: `https://lifecloud-qr.com/profile/${profile._id}`,
        };
        
        // await dispatch(createNotification(notification)); //TODO: Server notifications
      }))
    }
    alert('Candle and Flower added successfully');
  };

  const handleClick = () =>
  {
    Swal.fire({
      title: 'Are you sure you want to light up candle and flower?',
      input: 'text',
      inputAttributes: {
        autocapitalize: 'off',
        placeholder: `Type '確認' to proceed!`,
      },
      showCancelButton: true,
      confirmButtonText: 'Yes',
      showLoaderOnConfirm: true,
      preConfirm: (text) =>
      {
        if (text !== '確認' || text === '')
          Swal.showValidationMessage(`Please type '確認' to proceed!`);

        return lifeAxios(localStorage.getItem('jwt'))
          .get(`/candleFlowers/light-cf`)
          .then((response) =>
          {
            if (!response.statusText === 'OK') {
              throw new Error(response.statusText);
            }
          })
          .catch((error) =>
          {
            Swal.showValidationMessage(`Request failed: ${error}`);
          });
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((result) =>
    {
      if (result.isConfirmed) {
        Swal.fire('Candle and Flower has been lit up!');
      }
    });
  };

  const [isOrganization, setIsOrganization] = useState(false);

const handleReturn = () => {
window.location.href = `${window.location.origin}/admin`;
};


const handleLightAllClick = async () => {
  const dedicationText = document.getElementById('dedication').value.trim(); // Getting and trimming the dedication input value
  const id = document.getElementById('user').value.trim(); // Getting and trimming the user input value

  // Check if both fields are provided
  if (!id || !dedicationText) {
    Swal.fire('Error', 'Both user ID and Dedication Text are required!', 'error');
    return; // Stop execution if validation fails
  }

  // Construct the payload
  const payload = {
    id,
    dedicationText,
  };

  // console.log("Payload being sent: ", payload); // To check the payload

  try {
    // Dispatch the action to light all candles, passing the payload
    const result = await dispatch(lightAllCandles(payload)).unwrap(); // Unwrap to get the payload directly

    // Check if the response status is success
    if (result.status === 'success') {
      Swal.fire('Success', 'All candles and flowers have been lit up!', 'success');
      setTimeout(() => {
      window.location.reload(); // Reload the page after 3 seconds
    }, 3000);    } else {
      Swal.fire('Error', 'Unexpected response from the server. Please try again!', 'error');
    }
  } catch (error) {
    // Show the error message returned by the API if available, or a generic message
    Swal.fire('Error', error || 'Something went wrong. Please try again!', 'error');
  }
};


  return (
    <div>

      {user.role !== 'admin' && <h2

      >
        Currently the feature is unavailable. Contact with the developer
      </h2>}

      <form
        onSubmit={handleSubmit(onSubmit)}
         style={{
          background: 'rgba(255, 255, 255, 0.9)', // רקע שקוף מעט
          borderRadius: '20px', // פינות מעוגלות
          boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.2)', // הצללה
          padding: '30px', // ריווח פנימי
          width: '80%', // רוחב הטופס
          margin: '100px auto', // מרכז הטופס עם מרווח עליון של 100px
  }}
        className="container bg-light shadow p-5 my-5"
      >
  <button className="btn btn-primary btn-lg" onClick={handleLightAllClick}
  style={{
    margin: 15,
    top: '8%', 
    right: '15%',
    position: 'absolute',
    background: 'linear-gradient(145deg, #42a5f5, #1e88e5)',
    color: '#fff',
    fontSize: '15px',
    fontWeight:'bold',
    border: 'none',
    borderRadius: '33px',
    cursor: 'pointer',
    boxShadow: '0px 6px 12px rgba(0,,0,0.3)',
    transition: 'all 0.3s ease' 
    
  }}
  
  >
        Light All
      </button>
        <input type="checkbox" defaultChecked={isOrganization} onChange={() => { setIsOrganization(x => !x); }} />
        <label style={{ margin: 15 }}>Is Organization Profile</label>


        <div className="mb-3">
          <label htmlFor="profile" className="form-label">
            {isOrganization ? "Organization Profile Id" : "Profile Id"}
          </label>
          <button className= "back-button" onClick={handleReturn}>חזרה לראשי</button>
          <input
            type="text"
            className="form-control"
            id="profile"
            {...register('profile')}
             style={{
              border: '1px solid rgba(0, 0, 0, 0.2)', // גבול עדין
              borderRadius: '10px', // פינות מעוגלות
              padding: '10px', // ריווח פנימי
              fontSize: '16px', // גודל טקסט
              boxShadow: 'inset 0px 2px 5px rgba(0, 0, 0, 0.1)', // הצללה פנימית
  }}
          />
          <InputError error={errors.profile?.message} />
        </div>
        <div className="mb-3">
          <label htmlFor="dedication" className="form-label">
            Dedication Text
          </label>
          <input
            type="text"
            className="form-control"
            id="dedication"
            {...register('dedication')}
          />
          <InputError error={errors.dedication?.message} />
        </div>
        <div className="mb-3">
          <label htmlFor="user" className="form-label">
            User Id
          </label>
          <input
            type="text"
            className="form-control"
            id="user"
            {...register('user')}
          />
          <InputError error={errors.user?.message} />
        </div>

        <div className="mb-3">
          <label htmlFor="candles" className="form-label">
            Candles
          </label>

          <input
            type="number"
            className="form-control"
            id="candles"
            {...register('candles')}
          />
        </div>

        <div className="mb-3">
          <label htmlFor="flowers" className="form-label">
            Flowers
          </label>
          <input
            type="number"
            className="form-control"
            id="flowers"
            {...register('flowers')}
          />
        </div>
        <button className="btn btn-primary btn-lg"
           style={{
             background: 'linear-gradient(145deg, #42a5f5, #1e88e5)',
             color: '#fff',
             fontSize: '12px',
             fontWeight: 'bold',
             border: 'none',
             borderRadius: '30px',
             padding: '15px 25px',
             cursor: 'pointer',
             boxShadow: '0px 6px 12px rgba(0, 0, 0, 0.3)',
             transition: 'all 0.3s ease',
    }}
        
        >
          Submit
          </button>
      </form>
      {/* <div className="text-center">
        <button
          className="btn btn-danger btn-lg"
          style={{ fontSize: '3rem', padding: '2rem 3rem' }}
          onClick={handleClick}
        >
          LIGHT CANDLE AND FLOWER <i className="fas fa-lightbulb"></i>
        </button>
      </div> */}
    </div>
  );
};

export default LightCF;
