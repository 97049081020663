import React, { useState } from 'react';
import './UserDeletion.css';
import { useSelector } from 'react-redux';
import FindUser from '../../components/FindUser/FindUser';
import Button from '../../components/UI/Button/Button';

const UserDeletion = () => {
  const [transferSuccess, setTransferSuccess] = useState(null);
  const { user } = useSelector((state) => state.user);
  const [id, setId] = useState('');
  const [isTransferComplete, setIsTransferComplete] = useState(false);


  const handleTransfer = () => {
    const isSuccess = true;
    setTransferSuccess(isSuccess);
    setIsTransferComplete(isSuccess);
  };


  const handleReturn = () => {
window.location.href = `${window.location.origin}/admin`;
  };
const handleId = (e) => {
  setId(e.target.value);
};
  return (
    <div className="profile-transfer">
      {user.role !== 'admin' && <h2
        style={{
          color: 'crimson',
          position: 'absolute',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          textAlign: 'center',
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          width: '100%',
          height: '100vh',
          fontSize: '6rem',
          fontFamily: 'cursive',
        }}
      >
        Currently the feature is unavailable. Contact with the developer
      </h2>}
      <Button className="back-button" onClick={handleReturn}>חזרה לראשי</Button>
      <h1 className="title">מחיקת משתמש לפי ה ID</h1>
      <div className="comp1">
      <div className="input-container1">
        <div className="input-box" >
          <div className="input-box">
          <label htmlFor="profile-id">מזהה משתמש : </label>
          <input id="profile-id"  value={id} onChange={handleId} placeholder="הזן את ה ID"/>
          </div>
        </div>
      </div>
      <div className="profiles">
        <div className="profile-box">
          <FindUser id={id} />
        </div>
      </div>
      </div>
      {transferSuccess !== null && (
        <div className={`message ${transferSuccess ? 'success' : 'failure'}`}>
          {transferSuccess ? "המחיקה בוצעה בהצלחה" : "שגיאה במחיקה פנה למפתחים"}
        </div>
      )}
      <Button id="delete-button" onClick={handleTransfer} disabled={isTransferComplete}>מחיקה</Button>
    </div>
  );
};

export default UserDeletion;
