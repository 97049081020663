import { createSlice, createAsyncThunk, isAnyOf } from '@reduxjs/toolkit';
import lifeAxios from '../../utils/lifeAxios';

/* Initial State for profile */
const initialState = {
  candlesFlowers: [],
  loading: false,
  error: '',
};
/* For Getting Candle and Flower for specific profiles */
export const getCandlesFlowers = createAsyncThunk(
  'candleFlower/getCandlesFlowers',
  async (profileId, { rejectWithValue }) => {
    try {
      const response = await lifeAxios(localStorage.getItem('jwt')).get(
        `/candleFlowers/profile/${profileId}`
      );
      return response.data.data.data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);
/* For Lighting Candles and Flowers for All Profiles */
export const lightAllCandles = createAsyncThunk(
  'candleFlower/lightAllCandles',
  async (payload, { rejectWithValue }) => {
    try {
      const response = await lifeAxios(localStorage.getItem('jwt')).post(
        'candleFlowers/lightAllCandles',
        payload
      );
      return response.data; 
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);
/* For creating candle flower */
export const createCandleFlower = createAsyncThunk(
  'candleFlower/createCandleFlower',
  async (formData, { rejectWithValue }) => {
    try {
      const response = await lifeAxios(localStorage.getItem('jwt')).post(
        `/candleFlowers`,
        formData
      );
      return response.data.data.data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const createFundedCandleFlower = createAsyncThunk(
  'candleFlower/createFundedCandleFlower',
  async (formData, { rejectWithValue }) => {
    try {
      const response = await lifeAxios(localStorage.getItem('jwt')).post(
        `/candleFlowers/fundedCandleFlowers`,
        formData
      );
      return response.data.data.data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

/* For updating candle flower */
export const createCfComment = createAsyncThunk(
  'candleFlower/createCfComment',
  async (formData, { rejectWithValue, dispatch }) => {
    try {
      const response = await lifeAxios(localStorage.getItem('jwt')).patch(
        `/candleFlowers/createCfComment/${formData.cfId}`,
        formData
      );
      dispatch(getCandlesFlowers(formData.profileId));
      return response.data.data.data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

const candleFlower = createSlice({
  name: 'candleFlower',
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getCandlesFlowers.fulfilled, (state, action) => {
      state.candlesFlowers = action.payload;
    });
builder  .addCase(lightAllCandles.pending, (state) => {
        state.loading = true;
        state.error = '';
      })
    builder  .addCase(lightAllCandles.fulfilled, (state, action) => {
        state.loading = false;
        state.profilesProcessed = action.payload.data.profiles; // Use the API response to update state
      })
   builder   .addCase(lightAllCandles.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
    builder.addMatcher(
      isAnyOf(
        getCandlesFlowers.pending,
        createCandleFlower.pending,
        createCfComment.pending
      ),
      (state) => {
        state.loading = true;
      }
    );
    builder.addMatcher(
      isAnyOf(
        getCandlesFlowers.fulfilled,
        createCandleFlower.fulfilled,
        createCfComment.fulfilled
      ),
      (state) => {
        state.loading = false;
      }
    );
    builder.addMatcher(
      isAnyOf(
        getCandlesFlowers.rejected,
        createCandleFlower.rejected,
        createCfComment.rejected
      ),
      (state, action) => {
        state.loading = true;
        state.error = action.payload;
      }
    );
  },
});

export default candleFlower.reducer;
