import React, { useState } from 'react';
import './ProfileDeletion.css';
import { useSelector, useDispatch } from 'react-redux';
import FindProfile from '../../components/FindProfile/FindProfile';
import Button from '../../components/UI/Button/Button';
import { deleteById } from '../../features/profile/profileSlice';

const ProfileDeletion = () => {
  const [id, setId] = useState(''); // Stores the ID from input
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false); // Disable button during API call

  const { user } = useSelector((state) => state.user);
  const dispatch = useDispatch();

  const handleDelete = async () => {
    if (!id.trim()) {
      setError('Please enter a valid ID.');
      return;
    }

    setIsProcessing(true);
    setError(null); // Clear previous errors
    setSuccess(false); // Clear success messages

    try {
      // Pass `deleteId` in the payload as expected by the `deleteById` thunk
      const response = await dispatch(deleteById({ deleteId: id }));

      if (response.meta.requestStatus === 'fulfilled') {
        setSuccess(true);
        setError(null);
      } else {
        setSuccess(false);
        setError('Profile deletion failed. Please try again.');
      }
    } catch (err) {
      console.error('Error:', err);
      setError(`Error: ${err.message || 'Unknown error occurred'}`);
      setSuccess(false);
    } finally {
      setIsProcessing(false);
    }
  };

  const handleReturn = () => {
  window.location.href = `${window.location.origin}/admin`;
  };

  const handleIdChange = (e) => {
    setId(e.target.value); // Update state with input value
  };

  return (
    <div className="profile-transfer">
      {user.role !== 'admin' && (
        <h2
          style={{
            color: 'crimson',
            position: 'absolute',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            textAlign: 'center',
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            width: '100%',
            height: '100vh',
            fontSize: '6rem',
            fontFamily: 'cursive',
          }}
        >
          Currently the feature is unavailable. Contact with the developer
        </h2>
      )}
      <Button className="back-button" onClick={handleReturn}>
        חזרה לראשי
      </Button>
      <h1 className="title">מחיקת פרופיל לפי הID</h1>
      <div className="comp1">
        <div className="input-container1">
          <div className="input-box">
            <label htmlFor="profile-id">User ID</label>
            <input
              id="profile-id"
              value={id}
              onChange={handleIdChange} // Update state as user types
              placeholder="הזן את ה ID"
            />
          </div>
        </div>
        <div className="profiles">
          <div className="profile-box">
            <FindProfile id={id} />
          </div>
        </div>
      </div>
      {
        success && (
          <div className="message success" >
            <p>QR Swap was successful! </p>
          </div>
        )
      }
      {
        error && (
          <div className="message error" >
            <p>{error} </p>
          </div>
        )
      }
      <Button onClick={handleDelete} disabled={isProcessing}>
        {isProcessing ? 'Processing...' : 'מחיקה'}
      </Button>
    </div>
  );
};

export default ProfileDeletion;
