import React, { useState } from 'react';
import './ProfileToOrgTransfer.css';
import { useSelector, useDispatch } from 'react-redux'; // Import useDispatch
import FindOrg from '../../components/FindOrg/FindOrg';
import FindProfile from '../../components/FindProfile/FindProfile';
import Button from '../../components/UI/Button/Button';
import { addOrganization } from '../../features/profile/profileSlice';


const ProfileToOrgTransfer = () => {
  const [transferSuccess, setTransferSuccess] = useState(null);
  const [orgId, setOrgId] = useState('');
  const [profId, setProfId] = useState('');
  const [isTransferComplete, setIsTransferComplete] = useState(false);

  const dispatch = useDispatch(); // Initialize dispatch
  const { user } = useSelector((state) => state.user);


  const handleTransfer = async () => {
    try {
      const result = await dispatch(addOrganization({ profileId: profId, organizationId: orgId })).unwrap();
      console.log('Dispatch Result:', result);
      setTransferSuccess(true);
      setIsTransferComplete(true);
    } catch (error) {
      console.error('Error during transfer:', error);
      setTransferSuccess(false);
      setIsTransferComplete(false);
    }
  };


  const handleReturn = () => {
window.location.href = `${window.location.origin}/admin`;
  };
  const handleOrgId = (e) => {
    setOrgId(e.target.value);
  };
  const handleProfId = (e) => {
    setProfId(e.target.value);
  };

  return (
    <div className="profile-transfer">
      {user.role !== 'admin' && <h2
        style={{
          color: 'crimson',
          position: 'absolute',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          textAlign: 'center',
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          width: '100%',
          height: '100vh',
          fontSize: '6rem',
          fontFamily: 'cursive',
        }}
      >
        Currently the feature is unavailable. Contact with the developer
      </h2>}
      <Button className="back-button" onClick={handleReturn}>חזרה לראשי</Button>
      <h1 className="title">העברת פרופיל לתוך ארגון קיים</h1>
      <div className="comp1">
        <div className="input-container1">
          <div className="input-box">
            <label htmlFor="profile-id">Profile ID</label>
            <input id="profile-id" value={profId} onChange={handleProfId} placeholder="הזן את ה ID" />
          </div>
        </div>
        <div className="profiles">
          <div className="profile-box">
            <FindProfile id={profId} />
          </div>
        </div>
      </div>
      <div className="comp1">
        <div className="input-container1">
          <div className="input-box">
            <label htmlFor="org-id">Org ID</label>
            <input id="org-id" value={orgId} onChange={handleOrgId} placeholder="הזן את ה ID" />
          </div>
        </div>
        <div className="profiles">
          <div className="profile-box">
            <FindOrg id={orgId} />
          </div>
        </div>
      </div>
      {transferSuccess !== null && (
        <div className={`message ${transferSuccess ? 'success' : 'failure'}`}>
          {transferSuccess
            ? 'הכנסת הפרופיל לתוך הארגון בוצעה בהצלחה'
            : 'שגיאה בהוצאת הפרופיל פנה למפתחים'}
        </div>
      )}
      <Button id="enter-org" onClick={handleTransfer} disabled={isTransferComplete}>
        הכנס
      </Button>
    </div>
  );
};

export default ProfileToOrgTransfer;
