import React, { useState, useEffect } from 'react';
import './ProfileQRCrossing.css';
import { useSelector, useDispatch } from 'react-redux';
import FindProfile from '../../components/FindProfile/FindProfile';
import Button from '../../components/UI/Button/Button';
import { QRSwap } from '../../features/profile/profileSlice';

const ProfileQRCrossing = () => {
  const [transferSuccess, setTransferSuccess] = useState(null);
  const [oldId, setOldId] = useState('');
  const [newId, setNewId] = useState('');
  const dispatch = useDispatch();
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(null);

  const handleTransfer = async () => {
    try {
      const response = await dispatch(QRSwap({ newId, oldId }));
      console.log("API Response:", response); // Log the response to inspect its structure

      // Check if the response status is 'fulfilled' and if the payload is true
      if (response?.payload === true) {
        setSuccess(true);
        setError(null);
      } else {
        setSuccess(false);
        setError('QR Swap failed');
      }
    } catch (err) {
      console.error("Error:", err);  // Log the error if any
      setError("Error: " + err.message);
      setSuccess(false);
    }
  };


  const handleReturn = () => {
window.location.href = `${window.location.origin}/admin`;
  };

  const { user } = useSelector((state) => state.user);

  const handleOldId = (e) => {
    setOldId(e.target.value);
  };
  const handleNewId = (e) => {
    setNewId(e.target.value);
  };

  useEffect(() => {
    if (success) {
      const timer = setTimeout(() => {
        setSuccess(false); // Reset success state after 3 seconds
      }, 3000); // 3 seconds

      return () => clearTimeout(timer);
    }
  }, [success]);

  return (
<div id="qrCrossing" className="profile-transfer">
        {
        user.role !== 'admin' && (
          <h2
            style={
              {
                color: 'crimson',
                position: 'absolute',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                textAlign: 'center',
                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                width: '100%',
                height: '100vh',
                fontSize: '6rem',
                fontFamily: 'cursive',
              }
            }
          >
            Currently the feature is unavailable.Contact with the developer
          </h2>
        )}
      <Button className="back-button" onClick={handleReturn} > חזרה לראשי </Button>
      < h1 className="title" > החלפת\הצלבת ID לפרופילים לצורך QR </h1>
      < div className="comp1" >
        <div className="input-container1" >
          <div className="input-box" >
            <label htmlFor="profile-id" >הזן את ה ID הישן : </label>
            < input id="profile-id" value={oldId} onChange={handleOldId} placeholder="הזן את ה ID" />
          </div>
        </div>
        < div className="profiles" >
          <div className="profile-box" >
            <FindProfile id={oldId} />
          </div>
        </div>
      </div>
      < div className="comp1" >
        <div className="input-container1" >
          <div className="input-box" >
            <label htmlFor="org-id" >הזן את ה ID החדש :  </label>
            < input id="org-id" value={newId} onChange={handleNewId} placeholder="הזן את ה ID" />
          </div>
        </div>
        < div className="profiles" >
          <div className="profile-box" >
            <FindProfile id={newId} />
          </div>
        </div>
      </div>
      < Button  id="change-button" onClick={handleTransfer} > החלפה </Button>
      {
        success && (
          <div className="message success" >
            <p>QR Swap was successful! </p>
          </div>
        )
      }
      {
        error && (
          <div className="message error" >
            <p>{error} </p>
          </div>
        )
      }
    </div>
  );
};

export default ProfileQRCrossing;
