import React, { useEffect, useState } from 'react';
import classes from '../CreateMemory/CreateMemory.module.css';
import formClasses from '../Form.module.css';
import Button from '../../components/UI/Button/Button';
import CenterContentLayout from '../../components/layout/CenterContentLayout';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { editMemory, getMemory, deleteMemoryGalleryImage, addImageToGallery } from '../../features/memory/memorySlice';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useNavigate, useParams } from 'react-router-dom';
import toastCreator from '../../utils/toastifyCreator';
import InputError from '../../components/InputError/InputError';
import { Helmet } from 'react-helmet-async';
import lifeAxios from '../../utils/lifeAxios';
import getFileType from '../../utils/getFileType';

//Form validation schema
const schema = yup.object().shape({
  description: yup.string().required('*Description is required!'),
  media: yup.mixed(),
});
const EditMemory = () => {
  const { memoryId } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [media, setMedia] = useState(null);
  const [memoryGallery, setMemoryGallery] = useState([]);
  const [existingMemoryGallery, setExistingMemoryGallery] = useState([]);
  const { memory } = useSelector((state) => state.memory);
  const { user } = useSelector((state) => state.user);
  const { loading } = useSelector((state) => state.memory);
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({ resolver: yupResolver(schema) });

  useEffect(() => {
    (async () => {
      const response = await lifeAxios(localStorage.getItem('jwt')).get(
        `/memories/${memoryId}`
      );
      const memoryResponse = response.data.data.data;
      const media =
        getFileType(memoryResponse.media) === 'video'
          ? 'https://res.cloudinary.com/lifecloud-qr/image/upload/v1672574174/photo-1611162616475-46b635cb6868_l2gzrj.avif'
          : memoryResponse.media;

      setMedia(media);
      setExistingMemoryGallery(memoryResponse.gallery || []); // Assuming `gallery` contains the existing images
      reset(memoryResponse);
    })();
  }, [dispatch, memory, memoryId, reset]);


  const showNotification = (status, payload) => {
    if (status === 'error') return toastCreator('error', payload);
    navigate(`/memory/${payload}`);
  };

  const onChangeMedia = (event) => {
    if (event.target.files[0]) {
      const src = URL.createObjectURL(event.target.files[0]);
      setMedia(src);
    }
  };

  const onSubmit = async ({ description, media, title }) => {
    const formData = new FormData();
    formData.append('description', description);
    formData.append('title', title);

    // Add the media file to formData (only if media file exists)
    if (media[0]?.type) {
      formData.append('media', media[0]);
    }

    // Dispatch the first formData for updating the memory
    dispatch(editMemory({ memoryId, formData, showNotification }));

    // Check if memoryGallery is not empty
    if (memoryGallery && memoryGallery.length > 0) {
      // Create a new FormData object for the gallery images
      const galleryFormData = new FormData();

      // Loop over the gallery images, convert each blob URL to a File object
      for (let i = 0; i < memoryGallery.length; i++) {
        const blobUrl = memoryGallery[i];

        try {
          // Convert the Blob URL to a File object using fetch and Blob API
          const file = await fetch(blobUrl)
            .then((res) => res.blob())  // Convert the blob URL to a Blob object
            .then((blob) =>
              new File([blob], `gallery-image-${i}.jpg`, { type: blob.type })  // Create a File object
            );

          // Append the file to the gallery FormData
          galleryFormData.append('galleryImages', file);
        } catch (error) {
          console.error(`Error processing file at index ${i}:`, error);
          // Optionally handle the error, e.g., show a notification or skip this file
        }
      }

      // Dispatch the formData to add images to the gallery
      dispatch(addImageToGallery({ memoryId, formData: galleryFormData }));
    }

  };

  // Handle file input change
  const handleFileUpload = (event) => {
    const files = Array.from(event.target.files);
    const newImages = files.map((file) => URL.createObjectURL(file));
    setMemoryGallery((prevGallery) => [...prevGallery, ...newImages]); // Store Blob URLs temporarily
    console.log('Updated memoryGallery in handleFileUpload:', newImages); // Log the updated gallery immediately
  };



  const handleDelete = (imageUrl) => {
    // Show confirmation popup
    const confirmed = window.confirm('התמונה תמחק לתמיד, האם אתה בטוח?');

    if (confirmed) {
      // Dispatch the delete action if confirmed
      dispatch(deleteMemoryGalleryImage({ memoryId, imageUrl }));
    }
  };

  const ExistingGallery = ({ images, handleDelete }) => {
    return (
      <div style={{ marginBottom: '15px', marginTop: '15px' }}>
        <h1>גלריית זיכרון</h1>
        <div style={{ display: 'flex', flexWrap: 'wrap', gap: '10px' }}>
          {images.map((image, index) => (
            <div
              key={index}
              style={{
                position: 'relative',
                width: '150px',
                height: '150px',
              }}
            >
              <img
                src={image}
                alt={`Existing ${index + 1}`}
                style={{ width: '100%', height: '100%', objectFit: 'cover' }}
              />
              <button
                onClick={() => handleDelete(image)} // Optionally allow deletion of existing images
                style={{
                  position: 'absolute',
                  top: '5px',
                  right: '5px',
                  background: 'rgba(255, 0, 0, 0.8)',
                  color: 'white',
                  border: 'none',
                  borderRadius: '50%',
                  width: '25px',
                  height: '25px',
                  cursor: 'pointer',
                }}
                aria-label={`Delete existing image ${index + 1}`}
              >
                ×
              </button>
            </div>
          ))}
        </div>
      </div>
    );
  };


  const MemoryGallery = ({ memoryGallery, handleFileUpload, handleDelete }) => (
    <div>
      <h1>הוספת תמונות\סרטונים לגלריה (עד 10)</h1>
      <div style={{ marginBottom: '20px' }}>
        <input
          type="file"
          multiple
          accept="image/*"
          onChange={handleFileUpload}
          aria-label="Upload images"
        />
      </div>
      <div style={{ display: 'flex', flexWrap: 'wrap', gap: '10px' }}>
        {memoryGallery.map((image, index) => (
          <div
            key={index}
            style={{
              position: 'relative',
              width: '150px',
              height: '150px',
            }}
          >
            <img
              src={image}
              alt={`Uploaded ${index + 1}`}
              style={{ width: '100%', height: '100%', objectFit: 'cover' }}
            />
            <button
              onClick={() => handleDelete(image)} // Pass the image URL to the delete function
              style={{
                position: 'absolute',
                top: '5px',
                right: '5px',
                background: 'rgba(255, 0, 0, 0.8)',
                color: 'white',
                border: 'none',
                borderRadius: '50%',
                width: '25px',
                height: '25px',
                cursor: 'pointer',
              }}
              aria-label={`Delete image ${index + 1}`}
            >
              ×
            </button>
          </div>
        ))}
      </div>
    </div>
  );

  return (
    <React.Fragment>
      <Helmet>
        <title>LifeCloud | העלאת זכרון חדש</title>
        <meta
          property="og:url"
          content={`https://lifecloud-qr.com/edit-memory/${memoryId}`}
        />
      </Helmet>
      <CenterContentLayout cloudPos="right-left">
        <section className={classes.create_memory}>
          <h2>עריכת זיכרון</h2>
          <div className="container">
            <div className="row">
              <div className="col-lg-8 col-md-10 mx-auto">
                <form onSubmit={handleSubmit(onSubmit)} className="text-center">
                  <div className="d-flex justify-content-center">
                    <div className={classes.social}>
                      <label htmlFor="media">
                        {media ? (
                          <img
                            src={media}
                            alt="upload"
                            className={classes.preview_img}
                          />
                        ) : (
                          <div className={classes.img_container}>
                            <div className={classes.plus}>
                              <div className={`${classes.line}`}></div>
                              <div className={`${classes.line}`}></div>
                            </div>
                          </div>
                        )}
                        בחרו תמונה/סרטון/מצגת להעלאה
                        <input
                          type="file"
                          id="media"
                          name="media"
                          accept="image/*, video/*,.pptx"
                          className="d-none"
                          {...register('media', {
                            onChange: onChangeMedia,
                          })}
                        />
                      </label>
                    </div>
                  </div>
                  <div className={`row gx-5 ${classes.input_container}`}>
                    <div className="col-12">
                      <input
                        type="text"
                        className={`form-control ${formClasses.input} ${classes.input}`}
                        // placeholder="שם הזכרון."
                        {...register('title')}
                      // maxLength="15"
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <textarea
                        {...register('description')}
                        className={`form-control ${formClasses.input} ${formClasses.textarea} ${classes.textarea}`}
                        placeholder="תוכן הזכרון..."
                        dir="rtl"
                      ></textarea>
                      <InputError error={errors.description?.message} />
                    </div>
                  </div>
                  {/* Existing Gallery */}
                  <ExistingGallery
                    images={existingMemoryGallery}
                    handleDelete={handleDelete}
                  />

                  <MemoryGallery
                    memoryGallery={memoryGallery}
                    handleFileUpload={handleFileUpload}
                    handleDelete={handleDelete}
                  />
                  <div
                    className={`row gy-4 gy-md-5 ${classes.button_container}`}
                  >
                    <Button className={classes.button} loading={loading}>
                      פרסם
                    </Button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
      </CenterContentLayout>
    </React.Fragment>
  );
};

export default EditMemory;
