import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getOrganizationProfile } from '../../features/organizationProfile/organizationProfileSlice';

const FindOrg = ({ id }) => {
  const dispatch = useDispatch();
  const [orgProfile, setOrgProfile] = useState(null);

  useEffect(() => {
    const fetchProfile = async () => {
      if (id) {
        const profileData = await dispatch(getOrganizationProfile(id)).unwrap();
        setOrgProfile(profileData);
      }
    };

    fetchProfile();
  }, [dispatch, id]);
  return (
    <div>
      {orgProfile ? "Organization Name: " + orgProfile.organizationName : 'Organization not found'}
    </div>
  );
};

export default FindOrg;
