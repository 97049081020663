import React, { useState } from 'react';
import './ProfileIdEdit.css'; 
import { useSelector } from 'react-redux';
import FindProfile from '../../components/FindProfile/FindProfile';
import Button from '../../components/UI/Button/Button';

const ProfileIdEdit = () => {
  const [transferSuccess, setTransferSuccess] = useState(null); 
  const [oldId, setOldId] = useState(''); 
  const [newId, setNewId] = useState('');
  const [isTransferComplete, setIsTransferComplete] = useState(false);


  const handleTransfer = () => {
    const isSuccess = true;
    setTransferSuccess(isSuccess);
    setIsTransferComplete(isSuccess);
  };

    const handleReturn = () => {
    window.location.href = 'https://www.life.kic.yaweli.com/admin';
  };
  const { user } = useSelector((state) => state.user);

  const handleOldId = (e) => {
    setOldId(e.target.value);
  };
    const handleNewId = (e) => {
    setNewId(e.target.value);
  };

  return (
    <div className="profile-transfer">
      {user.role !== 'admin' && <h2
        style={{
          color: 'crimson',
          position: 'absolute',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          textAlign: 'center',
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          width: '100%',
          height: '100vh',
          fontSize: '6rem',
          fontFamily: 'cursive',
        }}
      >
        Currently the feature is unavailable. Contact with the developer
      </h2>}
      <Button className= "back-button" onClick={handleReturn}>חזרה לראשי</Button>
      <h1 className="title">עריכת ID של פרופיל לצורך QR</h1>
      <div className="comp1">
      <div className="input-container1">
        <div className="input-box">
          <label htmlFor="profile-id">**OLD ID</label>
          <input id="profile-id" value={oldId} onChange={handleOldId} placeholder="הזן את ה ID"/>
        </div>
      </div>
      <div className="profiles">
        <div className="profile-box">
          <FindProfile id={oldId} />
        </div>
      </div>
      </div>
      <div className="comp1">
      <div className="input-container1">
        <div className="input-box">
          <label htmlFor="org-id">**NEW ID</label>
          <input id="org-id" value={newId} onChange={handleNewId} placeholder="הזן את ה ID"/>
        </div>
      </div>
      <div className="profiles">
        <div className="profile-box">
          <FindProfile id={newId} />
        </div>
      </div>
      </div>
      {transferSuccess !== null && (
        <div className={`message ${transferSuccess ? 'success' : 'failure'}`}>
          {transferSuccess ? "החלפת ה ID התבצעה בהצלחה" : "שגיאה בהחלפת הID פנה למפתחים"}
        </div>
      )}
      <Button onClick={handleTransfer} disabled={isTransferComplete}>עריכה</Button>
    </div>
  );
};

export default ProfileIdEdit;
