import { createSlice, createAsyncThunk, isAnyOf } from '@reduxjs/toolkit';
import lifeAxios from '../../utils/lifeAxios';

/* Initial State for memory */
const initialState = {
  memories: [],
  memory: null,
  loading: false,
  error: '',
  memoryLoading: true,
};
/* For Creating Memory */
export const createMemory = createAsyncThunk(
  'memory/createMemory',
  async ({ formData, showNotification }, { rejectWithValue }) => {
    try {
      console.log("FormData entries:");
      for (let [key, value] of formData.entries()) {
        console.log(key, value);
      }
      const response = await lifeAxios(localStorage.getItem('jwt')).post(
        `/memories`,
        formData
      );
      showNotification('success', response.data.data.data);
      return response.data.data.data;
    } catch (error) {
      const errorMessage = error.response?.data?.message || 'An unexpected error occurred';
      showNotification('error', errorMessage);
      return rejectWithValue(errorMessage);
    }
  }
);


/* Get a Single Memory Details */
export const getMemory = createAsyncThunk(
  'memory/getMemory',
  async (memoryId, { rejectWithValue }) => {
    try {
      const response = await lifeAxios(localStorage.getItem('jwt')).get(
        `/memories/${memoryId}`
      );
      return response.data.data.data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

/* Update Memory Details */
export const editMemory = createAsyncThunk(
  'memory/editMemory',
  async ({ memoryId, formData, showNotification }, { rejectWithValue }) => {
    try {
      const response = await lifeAxios(localStorage.getItem('jwt')).patch(
        `/memories/${memoryId}`,
        formData
      );
      showNotification('success', memoryId);
      return response.data.data.data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

/* Getting Memory for Specific Profile */
export const getProfileMemories = createAsyncThunk(
  'memory/profile',
  async (profileId, { rejectWithValue }) => {
    try {
      const response = await lifeAxios(localStorage.getItem('jwt')).get(
        `/memories/profile-memories/${profileId}`
      );
      return response.data.data.data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

/* Delete Memory */
export const deleteMemory = createAsyncThunk(
  'memory/deleteMemory',
  async ({ memoryId, showNotification }, { rejectWithValue }) => {
    try {
      const response = await lifeAxios(localStorage.getItem('jwt')).delete(
        `/memories/${memoryId}`
      );
      showNotification('success');
      return response;
    } catch (error) {
      showNotification('error', error.response.data.message);
      return rejectWithValue(error.response.data.message);
    }
  }
);
/* Add Memory Like */
export const memoryLike = createAsyncThunk(
  'memory/like',
  async ({ memoryId, userId }, { rejectWithValue }) => {
    try {
      const response = await lifeAxios(localStorage.getItem('jwt')).post(
        `/memories/memory-like/${memoryId}`,
        {
          userId,
        }
      );
      return response.data.data.data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);
/* Add Memory Comment */
export const memoryComment = createAsyncThunk(
  'memory/memoryComment',
  async ({ memoryId, formData }, { rejectWithValue }) => {
    try {
      const response = await lifeAxios(localStorage.getItem('jwt')).post(
        `/memories/comment/${memoryId}`,
        formData
      );
      return response.data.data.data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

// Define the async thunk to add images to the gallery
export const addImageToGallery = createAsyncThunk(
  'memory/addImageToGallery',
  async ({ memoryId, formData }, { rejectWithValue }) => {
    try {
      console.log("Memory ID:", memoryId);
      console.log("FormData for Gallery:", formData);
      for (let [key, value] of formData.entries()) {
        console.log(key, value); // Log each field and its corresponding file
      }

      const response = await lifeAxios(localStorage.getItem('jwt')).post(
        `/memories/${memoryId}/gallery`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );

      await new Promise(resolve => setTimeout(resolve, 3000));
      return response.data; // assuming the backend sends back updated gallery data
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);


export const deleteMemoryGalleryImage = createAsyncThunk(
  'memory/deleteMemoryGalleryImage',
  async ({ memoryId, imageUrl }, { rejectWithValue }) => {
    try {
      const response = await lifeAxios(localStorage.getItem('jwt')).delete(
        `/memories/${memoryId}/gallery`,
        {
          data: { imageUrl }, // Send the image URL to delete
        }
      );

      await new Promise(resolve => setTimeout(resolve, 3000));
      return response.data; // Return the response if successful
    } catch (error) {
      return rejectWithValue(error.response.data); // Handle errors
    }
  }
);

/* Delete Memory Comment */
export const deleteMemoryComment = createAsyncThunk(
  'memory/deleteComment',
  async ({ memoryId, commentId }, { dispatch, rejectWithValue }) => {
    try {
      await lifeAxios(localStorage.getItem('jwt')).delete(
        `/memories/${memoryId}/comment/${commentId}`
      );
      dispatch(getMemory(memoryId));
    } catch (error) {
      rejectWithValue(error.response.data.message);
    }
  }
);
const memorySlice = createSlice({
  name: 'memory',
  initialState,
  extraReducers: (builder) => {
    /* All Memories */
    builder.addCase(getProfileMemories.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getProfileMemories.fulfilled, (state, action) => {
      state.memories = action.payload;
      state.loading = false;
    });
    builder.addCase(getProfileMemories.rejected, (state, action) => {
      state.memories = [];
      state.error = action.payload;
      state.loading = false;
    });
    /* Single Memory */
    builder.addCase(getMemory.pending, (state, action) => {
      state.memoryLoading = true;
    });
    builder.addCase(getMemory.fulfilled, (state, action) => {
      state.memory = action.payload;
      state.memoryLoading = false;
    });
    builder.addCase(getMemory.rejected, (state, action) => {
      state.memory = null;
      state.error = action.payload;
      state.memoryLoading = false;
    });
    /* Memory Like */
    builder.addCase(memoryLike.fulfilled, (state, action) => {
      state.memory.likes = action.payload.likes;
    });
    builder
      .addCase(deleteMemoryGalleryImage.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteMemoryGalleryImage.fulfilled, (state, action) => {
        state.loading = false;
        // If the image deletion is successful, update the gallery in the state
        const updatedMemory = action.payload; // Assuming the updated memory with gallery is returned
        state.memory = updatedMemory; // Update the memory in the state
      })
      .addCase(deleteMemoryGalleryImage.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });

    builder
      .addCase(addImageToGallery.pending, (state) => {
        state.loading = true;
      })
      .addCase(addImageToGallery.fulfilled, (state, action) => {
        state.loading = false;
        // Assuming the updated gallery data comes back as part of the response
        // Here we update the gallery with the new images
        state.memory.gallery = [...state.memory.gallery, ...action.payload.gallery];
      })
      .addCase(addImageToGallery.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
    /* Memory Comment */
    builder.addCase(memoryComment.fulfilled, (state, action) => {
      console.log(action.payload);
      if (action.payload?.data?.memory?.comments) {
        state.memory.comments = action.payload.data.memory.comments;
      }
    });


    /* Rest Builder */

    builder.addMatcher(
      isAnyOf(createMemory.pending, memoryComment.pending, editMemory.pending),
      (state) => {
        state.loading = true;
      }
    );
    builder.addMatcher(
      isAnyOf(
        createMemory.fulfilled,
        memoryComment.fulfilled,
        editMemory.fulfilled
      ),
      (state) => {
        state.loading = false;
      }
    );
    builder.addMatcher(
      isAnyOf(
        createMemory.rejected,
        deleteMemory.rejected,
        memoryLike.rejected,
        memoryComment.rejected,
        deleteMemoryComment.rejected,
        editMemory.rejected
      ),
      (state, action) => {
        state.loading = false;
        state.error = action.payload;
      }
    );
  },
});

export default memorySlice.reducer;
