import React, { useState } from 'react';
import './EditPermissions.css'; 
import { useSelector } from 'react-redux';
import FindUser from '../../components/FindUser/FindUser';
import Button from '../../components/UI/Button/Button';


const EditPermissions = () => {
  const [transferSuccess, setTransferSuccess] = useState(null);
  const [selectedRole, setSelectedRole] = useState(""); // State to hold selected role
  const { user } = useSelector((state) => state.user);
  const [userId, setUserId] = useState('');
  const [isTransferComplete, setIsTransferComplete] = useState(false);


  const handleTransfer = () => {
    const isSuccess = true;
    setTransferSuccess(isSuccess);
    setIsTransferComplete(isSuccess);
  };

  const handleReturn = () => {
    window.location.href = 'https://www.life.kic.yaweli.com/admin';
  };

  const handleRoleChange = (event) => {
    setSelectedRole(event.target.value);
  };
  const handleUserId = (e) => {
    setUserId(e.target.value);
  };

  return (
    <div className="profile-transfer">
      {user.role !== 'admin' && <h2
        style={{
          color: 'crimson',
          position: 'absolute',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          textAlign: 'center',
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          width: '100%',
          height: '100vh',
          fontSize: '6rem',
          fontFamily: 'cursive',
        }}
      >
        Currently the feature is unavailable. Contact with the developer
      </h2>}
      <Button className="back-button" onClick={handleReturn}>חזרה לראשי</Button>
      
      <h1 className="title">עריכת הרשאות למשתמש</h1>
      <div className="comp1">
      <div className="input-container1">
        <div className="input-box">
          <label htmlFor="profile-id">User ID</label>
          <input id="profile-id" value={userId} onChange={handleUserId} placeholder="הזן את ה ID" />
        </div>
        <div className="dropdown1">
          <label htmlFor="role-dropdown">בחירת תפקיד:</label>
          <select id="role-dropdown" value={selectedRole} onChange={handleRoleChange}>
            <option value="user">User</option>
            <option value="admin">Admin</option>
            <option value="organization">Organization</option>
          </select>
        </div>
      </div>
      <FindUser  id={userId}/>
      </div>
      {transferSuccess !== null && (
        <div className={`message ${transferSuccess ? 'success' : 'failure'}`}>
          {transferSuccess ? "עריכת התפקיד בוצעה בהצלחה" : "עריכת התפקיד הכשלה פנה למפתחים"}
        </div>
      )}
      <Button onClick={handleTransfer} disabled={isTransferComplete}>שמירה</Button>
    </div>
  );
};

export default EditPermissions;
