import React, { useState } from 'react';
import classes from './PillBox.module.css';
import moment from 'moment';

const PillBox = ({
  img,
  additionalImg,
  date,
  event,
  imgPos = 'left',
  position = 'evenly',
  className = '',
  userName,
  onClick,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false); // State to control modal visibility
  const [selectedImage, setSelectedImage] = useState(''); // State to store the selected image URL
  const time = moment(date).format('DD.MM.YYYY');
  const day = moment(date).format('hh:mm');

  // Function to open modal with the clicked image
  const openImageModal = (image) => {
    setSelectedImage(image);
    setIsModalOpen(true);
  };

  // Function to close modal
  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedImage('');
  };

  return (
    <div
      className={`${classes.wrapper} d-flex align-items-center justify-content-${position} ${className}`}
    >
      {imgPos === 'left' && (
        <div className={classes.img_container}>
          <img src={img} alt="notification created user" />
        </div>
      )}
      {onClick && (
        <button
          type="button"
          className={`me-auto ${classes.pill_button}`}
          onClick={onClick}
        >
          מחק
        </button>
      )}
      <div className={classes.text_container}>
        <p className={classes.event} dir="rtl">
          {event}
        </p>
        <div className={classes.time}>
          {userName && <span className="mx-2"> :{userName}</span>}{' '}
          <span>{time}</span>
          <span className={classes.bar}>|</span>
          <span>{day}</span>
        </div>
      </div>
      {imgPos === 'right' && (
        <div className={classes.img_container}>
          <img src={img} alt="notification created user" />
        </div>
      )}
      
{/* Additional Image */}
{additionalImg && (
  <div
    className={classes.additional_img_container}
    style={{ display: additionalImg ? 'block' : 'none' }}
    onClick={() => openImageModal(additionalImg)}
  >
    <img src={additionalImg} alt="additional badge" />
  </div>
)}


      {/* Modal for enlarged image */}
      {isModalOpen && (
        <div className={classes.modal} onClick={closeModal}>
          <div className={classes.modal_content} onClick={(e) => e.stopPropagation()}>
            <img src={selectedImage} alt="Enlarged view" className={classes.modal_image} />
          </div>
        </div>
      )}
    </div>
  );
};

export default PillBox;
