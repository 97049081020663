import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getProfile } from '../../features/profile/profileSlice';

const FindProfile = ({ id }) => {
  const dispatch = useDispatch();
  const [profile, setProfile] = useState(null);

  useEffect(() => {
    const fetchProfile = async () => {
      if (id) {
        const profileData = await dispatch(getProfile(id)).unwrap();
        setProfile(profileData);
      }
    };

    fetchProfile();
  }, [dispatch, id]);
  return (
    <div>
      {profile ? "Profile Name: " + profile.firstName : 'Profile not found'}
      {profile ? " " + profile.lastName : null}
    </div>
  );
};

export default FindProfile;
