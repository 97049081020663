import React, { useState } from 'react';
import './AdminPage.css';
import { useSelector, useDispatch } from 'react-redux';
import * as XLSX from 'xlsx';
import { xl } from '../../features/user/userSlice';

const AdminPage = () => {
  const { user } = useSelector((state) => state.user);
const dispatch = useDispatch();
const { usersList } = useSelector((state) => state.user); // Fetch usersList from Redux


  const handleLCF = () => {
    window.location.href = `${window.location.origin}/light-candle-flower`;
  };
  const handlePTO = () => {
    window.location.href = `${window.location.origin}/ProfileToOrgTransfer`;
  };
  const handlePOO = () => {
    window.location.href = `${window.location.origin}/ProfileOutOfOrg`;
  };
  const handlePQC = () => {
    window.location.href = `${window.location.origin}/ProfileQRCrossing`;
  };
  const handlePD = () => {
    window.location.href = `${window.location.origin}/ProfileDeletion`;
  };
  const handlePIE = () => {
    window.location.href = `${window.location.origin}/ProfileIdEdit`;
  };
  const handleEP = () => {
    window.location.href = `${window.location.origin}/EditPermissions`;
  };
    const handleUD = () => {
    window.location.href = `${window.location.origin}/UserDeletion`;
  };

const handleXL = async () => {
  try {
    let users = usersList;

    // Fetch users if usersList is empty
    if (!usersList?.length) {
      users = await dispatch(xl()).unwrap(); // Get updated data directly
    }

    // Map user data to Excel rows
const data = users.map((user, index) => {
  return {
    FirstName: user.FirstName || 'N/A', // Match exact property name from the user object
    LastName: user.LastName || 'N/A',  // Match exact property name from the user object
    Email: user.Email || 'N/A',        // Match exact property name from the user object
  };
});



    if (data.length === 0) {
      console.error('No valid data available to generate Excel file.');
      return;
    }

    // Create a new workbook and worksheet
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(data);


    // Append worksheet to workbook
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Users');

    // Write file and trigger download
    XLSX.writeFile(workbook, 'users.xlsx');
    console.log('Excel file generated successfully!');
  } catch (error) {
    console.error('Error generating Excel file:', error);
  }
};




  return (
    <div className="container1">
{user.role !== "admin" && (
  console.log(user), // This will execute and log the role
  <h2
    style={{
      color: 'crimson',
      position: 'absolute',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      textAlign: 'center',
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      width: '100%',
      height: '100vh',
      fontSize: '6rem',
      fontFamily: 'cursive',
    }}
  >
    Currently the feature is unavailable. Contact with the developer
  </h2>
)}
      <header className="head1">
        <h1>,שלום אדמין </h1>
      </header>
      <main>
    <div className="button-list">
          <button onClick={handlePTO}>פרופיל לתוך ארגון
          <span className="tooltip">מעביר פרופיל לארגון</span>
          </button>
          <button onClick={handlePOO}>להוציא פרופיל מארגון
          <span className="tooltip">מוציא פרופיל מארגון</span>
          </button>
          <button onClick={handlePQC}>הצלבת פרופיל
          <span className="tooltip">מבצע הצלבה לפרופיל</span>
          </button>
          <button onClick={handlePD}>מחיקת פרופיל
          <span className="tooltip">מוחק פרופיל</span>
          </button>
          <button onClick={handleUD}>מחיקת משתמש
          <span className="tooltip">מוחק משתמש</span>
          </button>
          <button onClick={handleLCF}>הדלקת נרות
          <span className="tooltip">עובר לעמוד הדלקת נרות</span>
          </button>
          <button onClick={handleEP} disabled={true}>שינוי הרשאות
          <span className="tooltip">משנה הרשאות</span>
          </button>
          <button onClick={handleXL} >XL משתמשים
          <span className="tooltip">מוריד קובץ XL של משתמשים</span>
          </button>
        </div>
      </main>
    </div>
  );
};

export default AdminPage;


          // <button onClick={handlePIE} disabled={true}>עריכת פרופיל ID
          // <span className="tooltip">פרופיל ID עורך</span>
          // </button>
